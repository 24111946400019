import React, {ElementType, FC} from "react";
import {Box, Stack, SvgIconProps} from "@mui/material";
import {SectionDivider} from "../oropuro_layout/section_devider/SectionDevider";
import {GameType} from "../../features/play_games/models/models";
import {useGameSectionLogic} from "./useGameSectionLogic";
import {useAutoSize} from "./useAutoSize";
import {GameCard} from "../oropuro_layout/games/GameCardV2";
import {getMediaCDN} from "../../config";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";


export const GamesSection: FC<{gameType?: GameType, title?: string, icon?: ElementType<SvgIconProps>, link?: string}> = ({gameType, title, icon, link}) => {
    const { filteredGames, offset, page, totalPages, switchPage } = useGameSectionLogic(gameType)
    const {smallScreen: sm} = useCommonMediaQueries()
    const gap = 1.6
    const gameCardWidth = useAutoSize(8, gap)
    const cdn = getMediaCDN()

    return <Stack gap={2}>
        <SectionDivider
            icon={icon}
            title={title}
            link={link}
            leftButtonDisabled={page <= 1}
            rightButtonDisabled={page === totalPages}
            onLeftButtonClick={() => switchPage('back')}
            onRightButtonClick={() => switchPage('forward')}
        />

        <Box
            overflow={'auto'}
            display={'flex'}
            gap={gap}
        >
            {filteredGames?.slice(offset, offset + 8).map((game) => <GameCard
                mobileMode={sm}
                cdn={cdn ?? '/cdn'}
                width={gameCardWidth}
                key={game.internal_id}
                game={game}
                onGameClick={() => console.log(game)}
            />)}
        </Box>

    </Stack>
}