import React, {FC, useState} from "react";
import {alpha, Box, Paper, Stack} from "@mui/material";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {ExpandMore} from "@mui/icons-material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Typography from "@mui/material/Typography";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {Lang} from "../../../features/localisation/useLanguageInterface";
import {FooterDisclaimerContentEn} from "./footer_disclaimer_localization/FooterDisclaimerContent.en";
import {FooterDisclaimerContentDe} from "./footer_disclaimer_localization/FooterDisclaimerContent.de";
import {FooterDisclaimerContentEs} from "./footer_disclaimer_localization/FooterDisclaimerContent.es";
import {FooterDisclaimerContentPt} from "./footer_disclaimer_localization/FooterDisclaimerContent.pt";
import {FooterDisclaimerContentFr} from "./footer_disclaimer_localization/FooterDisclaimerContent.fr";
import { FooterDisclaimerContentRu } from "./footer_disclaimer_localization/FooterDisclaimerContent.ru";

export const FooterDisclaimer: FC = () => {
    const {smallScreen: sm} = useCommonMediaQueries()
    const [expanded, setExpanded] = useState<boolean>(false);
    const {languagePack: {pack: {argentina: {footer: footerLang}}, lang}} = useLanguage()

    return <Paper
        onClick={() => setExpanded(prevState => !prevState)}
        sx={theme => ({
            backgroundColor: '#101317',
            borderColor: alpha(theme.palette.text.primary, 0.1),
            p: sm ? 2 : 3,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
            cursor: 'pointer'
        })}
    >
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'top'}
        >
            <Typography variant={'h3'} fontSize={theme => theme.typography.pxToRem(sm ? 20 : 26)}>
                {footerLang.disclaimerTitle}
            </Typography>
            {expanded ? <ExpandLessIcon/> : <ExpandMore/>}
        </Box>


        <Stack
            overflow={'hidden'}
            maxHeight={expanded ? undefined : '80px'}
            sx={theme => ({
                ...(expanded
                    ? {}
                    : {
                        position: 'relative',
                        overflow: 'hidden',
                        WebkitMaskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
                        maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
                        WebkitMaskRepeat: 'no-repeat',
                        maskRepeat: 'no-repeat',
                        WebkitMaskSize: '100% 100%',
                        maskSize: '100% 100%',
                    }),
                '& ul': {
                    mt: 0,
                    mb: 1,
                    listStyle: 'none',
                    pl: 2,
                    '& li': {
                        position: 'relative',
                        pl: '16px',
                        fontSize: '16px',
                        lineHeight: '1.5',
                        '& > span': {
                            color: alpha(theme.palette.text.primary, 0.6)
                        }
                    },
                    '& li::before': {
                        content: '"•"',
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        fontSize: '18px',
                    },
                },
                '& p': {
                    color: alpha(theme.palette.text.primary, 0.6),
                },
                '& h4': {
                    fontSize: theme.typography.pxToRem(18),
                    lineHeight: 3
                }
            })}
        >
            {(() => {
                switch (lang) {
                    case Lang.DE:
                        return <FooterDisclaimerContentDe/>
                    case Lang.ES:
                        return <FooterDisclaimerContentEs/>
                    case Lang.PT:
                        return <FooterDisclaimerContentPt/>
                    case Lang.FR:
                        return <FooterDisclaimerContentFr/>
                    case Lang.RU:
                        return <FooterDisclaimerContentRu/>
                    case Lang.EN:
                    default:
                        return <FooterDisclaimerContentEn/>
                }
            })()}
        </Stack>
    </Paper>
}