import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const ProvidersIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.1,1c1,0,1.8,0.5,2.6,1.3h0L15,3.5c0.1-0.2,0.3-0.4,0.5-0.6c1.5-1.5,4-1.5,5.6,0
		c1.5,1.5,1.5,4,0,5.6c-0.2,0.2-0.4,0.4-0.6,0.5l1.3,1.3v0c0.8,0.8,1.3,1.6,1.3,2.6c0,1-0.6,1.8-1.3,2.5l-1.3,1.3
		c-0.5,0.5-1.1,0.4-1.5,0.3c-0.4-0.2-0.8-0.5-0.9-1.1c-0.1-0.4-0.2-0.8-0.5-1.1c-0.8-0.8-2-0.8-2.8,0s-0.8,2,0,2.8
		c0.3,0.3,0.7,0.5,1.1,0.5c0.5,0.1,0.9,0.5,1.1,0.9c0.2,0.4,0.2,1-0.3,1.5l-1.3,1.3c-0.7,0.7-1.5,1.3-2.5,1.3c-1,0-1.8-0.5-2.6-1.3
		L9,20.4c-0.1,0.2-0.3,0.4-0.5,0.6c-1.5,1.5-4,1.5-5.6,0c-1.5-1.5-1.5-4,0-5.6c0.2-0.2,0.4-0.4,0.6-0.5l-1.3-1.3v0
		C1.5,12.9,1,12.1,1,11.1c0-1,0.6-1.8,1.3-2.5v0l1.3-1.3C4,6.9,4.7,6.9,5,7.1c0.4,0.2,0.8,0.5,0.9,1.1C6,8.5,6.2,8.9,6.4,9.2
		c0.8,0.8,2,0.8,2.8,0s0.8-2,0-2.8C8.9,6.2,8.5,6,8.1,5.9C7.6,5.8,7.2,5.4,7.1,5C6.9,4.7,6.9,4,7.3,3.6l1.3-1.3
		C9.3,1.6,10.1,1,11.1,1z M11.1,3c-0.2,0-0.5,0.1-1.1,0.7l0,0L9.5,4.2c0.4,0.2,0.8,0.4,1.1,0.8c1.5,1.5,1.5,4,0,5.6s-4,1.5-5.6,0
		c-0.3-0.3-0.6-0.7-0.8-1.1L3.7,10l0,0C3.1,10.6,3,10.9,3,11.1c0,0.2,0.1,0.6,0.7,1.1L6,14.6c0.3,0.3,0.4,0.7,0.2,1
		c-0.1,0.4-0.4,0.6-0.8,0.7c-0.4,0.1-0.8,0.2-1.1,0.6c-0.8,0.8-0.8,2,0,2.8c0.8,0.8,2,0.8,2.8,0c0.3-0.3,0.5-0.7,0.6-1.1
		c0-0.4,0.3-0.7,0.7-0.8c0.4-0.1,0.8,0,1,0.2l2.3,2.3l0,0c0.6,0.6,0.9,0.7,1.1,0.7c0.2,0,0.6-0.1,1.1-0.7h0l0.5-0.6
		c-0.4-0.2-0.8-0.4-1.1-0.8c-1.5-1.5-1.5-4,0-5.6s4-1.5,5.6,0c0.3,0.3,0.6,0.7,0.8,1.1l0.6-0.5l0,0c0.6-0.6,0.7-0.9,0.7-1.1
		c0-0.2-0.1-0.6-0.7-1.1L18,9.4c-0.3-0.3-0.4-0.7-0.2-1c0.1-0.4,0.4-0.6,0.8-0.7c0.4-0.1,0.8-0.2,1.1-0.6c0.8-0.8,0.8-2,0-2.8
		c-0.8-0.8-2-0.8-2.8,0c-0.3,0.3-0.5,0.7-0.6,1.1c0,0.4-0.3,0.7-0.7,0.8c-0.4,0.1-0.8,0-1-0.2l-2.3-2.3C11.7,3.1,11.3,3,11.1,3z"/>
            </svg>
        </SvgIcon>
    );
}

export default ProvidersIcon;