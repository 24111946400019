import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const frLang: LangPack = {
    lang: Lang.FR,
    langName: "French",
    langShort: "fr",
    pack: {
        common: {
            noBTN: "Non",
            yesBTN: "Oui",
            errorCodeTitle: "Code d'erreur",
            pageSizeSwitcherLabel: "Lignes par page",
            ageConfirmationMessage: "Confirmez-vous que vous avez 18 ans ?",
            iConfirmBTN: "Je confirme",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "Êtes-vous sûr de vouloir vous déconnecter ?",
            logOut: "Déconnexion",
            depositMoney: "Déposer de l'argent",
            gamesHistory: "Historique des jeux",
            transactions: "Transactions",
            paymentAccounts: "Comptes de paiement",
            userInfo: "Informations utilisateur",
            withDrawMoney: "Retirer de l'argent"
        },
        mobileMenu: {
            profile: 'Profil',
            topUpBalance: 'Recharger le solde',
            logout: 'Se déconnecter'
        },
        dashboard: {
            title: 'Tableau de bord',
            youMustBeLoggedMessage: 'Vous devez être connecté pour voir cette section !'
        },
        internalErrorMessages: {
            networkError: "Une erreur réseau s'est produite lors de l'exécution de la requête.",
            error502: "Une erreur de serveur 502 s'est produite lors de l'exécution de la requête."
        },
        errorMessages: {
            2: "Aucune entrée",
            3: "Adresse e-mail utilisateur déjà existante",
            4: "Numéro de téléphone utilisateur déjà existant",
            5: "Utilisateur déjà connecté",
            6: "Mot de passe incorrect",
            7: "Résultat de jeux vide",
            8: "ID de résultat ne correspond pas à l'ID de la demande",
            9: "Erreur de demande de résultat",
            10: "Aucune ID de session dans la réponse",
            11: "Utilisateur déjà existant",
            12: "Votre jeton n'est pas valide. Connectez-vous à nouveau.",
            13: "Vous n'avez pas assez de droits pour effectuer cette action",
            14: "ID utilisateur manquante pour cette action",
            15: "Montant d'argent manquant",
            16: "L'action n'existe pas",
            17: "Jetons manquants",
            18: "Pas assez d'argent",
            19: "Vous devez être le parent de cet utilisateur pour effectuer cette action",
            20: "L'utilisateur n'existe pas",
            21: "Le type de valeur pour ce champ est incorrect",
            22: "Mot de passe manquant",
            23: "Nom d'utilisateur manquant",
            24: "ID de jeu manquante",
            25: "Nouveau mot de passe manquant",
            26: "Le jeton n'a pas pu être configuré",
            27: "Le jeton n'a pas pu être supprimé",
            28: "ID utilisateur manquante",
            29: "Format de date et d'heure incorrect",
            30: "Impossible de mettre à jour le bannissement de l'utilisateur",
            31: "Cet utilisateur doit être dans votre arbre pour effectuer cette action",
            32: "Cet utilisateur est banni et ne peut pas effectuer d'actions",
            33: "Devise manquante",
            34: "Valeur maximale manquante",
            35: "Le type de limite doit être une fois, quotidien, hebdomadaire ou mensuel",
            36: "Type de limite manquant",
            37: "Le fournisseur n'existe pas",
            38: "Montant de condition de palier manquant",
            39: "Type de condition de palier manquant",
            40: "Titre de palier manquant",
            41: "Le cashback pour ce palier existe déjà",
            42: "Pas de cashback pour ce palier",
            43: "Le bonus pour ce code promo existe déjà",
            44: "Le bonus n'existe pas",
            45: "Code promo manquant",
            46: "Heure de début manquante",
            47: "Montant de condition de dépôt manquant",
            48: "Mise manquante",
            49: "Jours de validité manquants",
            50: "ID manquant",
            51: "Type de cashback manquant, doit être 'mise' ou 'dépôt'",
            52: "Taux de cashback manquant",
            53: "Cette devise n'est pas disponible",
            54: "Paramètre d'interdiction manquant - vrai ou faux",
            55: "Le groupe bancaire pour cette devise n'est pas valide",
            56: "Nom de méthode de paiement manquant",
            57: "Paramètres obligatoires pour cette méthode de paiement manquants",
            58: "Cette méthode de paiement n'existe pas",
            59: "Cette méthode de paiement ne prend pas en charge la devise de cet utilisateur",
            60: "Cet utilisateur n'a pas de compte existant pour ce système de paiement",
            61: "Le parent de cet utilisateur n'a pas de compte existant pour ce système de paiement",
            62: "ID de transfert manquante",
            63: "Aucun transfert avec cette ID",
            64: "Ce transfert est demandé par un parent différent",
            65: "Vous avez toujours un bonus qui n'a pas expiré"
        },
        loginForm: {
            usernameFieldLabel: "Prénom",
            passwordFieldLabel: "Mot de passe",
            loginBTN: "Connexion"
        },
        userInfoForm: {
            emailIsRequiredMess: 'L\'e-mail est requis',
            notValidEmail: 'Entrez une adresse e-mail valide',
            currentPassFieldLabel: "Mot de passe actuel",
            emailFieldLabel: "E-mail",
            emptyFieldWarningMessage: "Ce champ ne doit pas être vide",
            firstnameFieldLabel: "Prénom",
            lastnameFieldLabel: "Nom de famille",
            newPassFieldLabel: "Nouveau mot de passe",
            passNotMatchWarningMessage: "Le nouveau mot de passe et le mot de passe de re-saisie ne correspondent pas",
            phoneFieldLabel: "Téléphone",
            retypePassFieldLabel: "Re-saisir le nouveau mot de passe",
            saveInfoBTN: "Sauvegarder les informations",
            updatePassBTN: "Mettre à jour le mot de passe",
            usernameLabel: "Surnom"
        },
        transactionsTable: {
            amountTableHeader: "Montant",
            methodTableHeader: "Méthode",
            statusTableHeader: "Statut",
            depositWithdrawTableHeader: "Dépôt/Retrait",
            dateTimeTableHeader: "Date/Heure"
        },
        gamesHistory: {
            providerTableHeader: 'Fournisseur',
            gameTableHeader: 'Jeu',
            betAmountTableHeader: 'Montant de la mise',
            winAmountTableHeader: 'Montant du gain',
            dateTimeTableHeader: 'Date/Heure'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Il n'y a plus de méthodes de paiement disponibles",
            paymentMethodsDropdownLabel: "Méthodes de paiement",
            addBTN: "Ajouter",
            yourPaymentAccountsTitle: "Vos comptes de paiement",
            addPaymentMethodBTN: "Ajouter une méthode de paiement"
        },
        moneyInput: {
            maxAmountMessage: "Valeur maximale",
            exceedAmountError: "Valeur maximale dépassée.",
            moreThanOneDotError: "Vous avez ajouté un point en trop.",
            moreThanTwoDecimals: "Vous avez saisi plus de deux décimales."
        },
        withdrawMoney: {
            requestPayoutLabel: "Retirer de l'utilisateur",
            moneySuccessfulRequestedMessage: "Vous avez demandé %money% pour le paiement.",
            requestBtn: "Demander de l'argent"
        },
        argentina: {
            common: {
                linkToAllTitle: "Tous les jeux",
                topGames: 'Jeux populaires',
                providers: 'Fournisseurs',
                seeAllBTN: 'Tout',
                returnBackBTN: 'Retour'
            },
            dashboard: {
                sideMenu: {
                    userInfo: 'Informations utilisateur',
                    transactions: 'Transactions',
                    gamesHistory: 'Historique des jeux',
                    logoutBTN: 'Déconnexion'
                },
                userInfo: {
                    userInfoSection: 'Informations utilisateur',
                    changePasswordSection: 'Changer le mot de passe'
                }
            },
            carousel: {
                slide1: {
                    header: 'Votre Parcours Gagnant<br/>Commence chez Imagine Live',
                    tagline: 'De vrais croupiers, de vrais gains.'
                },
                slide2: {
                    header: 'Déchaîne le<br/>Frisson des Sports Virtuels',
                    tagline: 'Jouez. Gagnez. Répétez.'
                },
                slide3: {
                    header: 'Bientôt sur <br/>casino en direct',
                    tagline: 'Vos fournisseurs préférés'
                },
                slide4: {
                    header: 'Tu aimes les bonbons ?',
                    tagline: 'Alors attends Galaxsys !'
                },
                slide5: {
                    header: 'Ezugi vous attend! <br/>Jouez directement sur OroPuro',
                    tagline: 'Jouez et gagnez!'
                },
            },
            menuLinkTitles: {
                home: "Accueil",
                casino: "Casino",
                liveCasino: "Live Casino",
                virtualGames: "Jeu virtuel",
            },
            sideBarSections: {
                games: "Jeu",
                topCrashGames: "Jeu Top Crash",
                other: "Autre",
                sportsRules: "Regle du sport"
            },
            headerMenu: {
                home: 'Accueil',
                casino: 'Casino',
                slots: 'Machines à sous',
                shooters: 'Tireur',
                tableGames: 'Jeux de table',
                liveCasino: 'Live Casino',
                crash: 'Crash',
                sport: 'Sport',
                sportPrelive: 'Prelive',
                sportSubCatTitle: 'Sport',
                gamesSubCatTitle: 'Jeux',
                menu: 'Menu'
            },
            categoriesDescription: {
                casino: 'Tournez pour gagner de gros jackpots !',
                slots: 'Tournez pour gagner de gros jackpots !',
                shooters: 'Visez, tirez et marquez des points !',
                tableGames: 'Jeux classiques, plaisir infini !',
                liveCasino: 'Jeux avec croupiers en direct !',
                crash: 'Pariez, regardez et encaissez rapidement !',
                sport: 'Pariez sur les sports avec nous !'
            },
            footer: {
                backToTopBTN: 'Retour en haut',
                copyright: 'Tous droits réservés © OroPuro 2024<br/>18+ pariez de manière responsable',
                disclaimerTitle: 'OroPuro Casino en ligne : Votre destination ultime pour des jeux premium'

            },
            topWins: {
                game: 'Jeu',
                date: 'Date',
                user: 'Utilisateur',
                betAmount: 'Montant de la mise',
                multiplier: 'Multiplicateur',
                payout: 'Gain'
            },
            cashback: {
                title: 'Le cashback est à vous !',
                gotItBTN: 'Compris',
            }
        },
        gameSearch: {
            placeholder: 'Tapez le nom du jeu ici'
        }
    }
}
