import {TopWinGame} from "./model";

export interface TopWinsState {
    topWins: TopWinGame[] | null
    isTopWinsLoading: boolean
}

export const initial: TopWinsState = {
    topWins: null,
    isTopWinsLoading: false
}