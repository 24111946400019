import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const LiveCasinoIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M16.8,1.4c2.9,1.3,5.1,3.4,6.6,6.1C23.8,8.3,24,9.1,24,9.9c0,1.1-0.3,2.1-1,3
		c-2,2.9-4.4,5.4-7.2,7.6c-0.7,1.1-1.7,1.9-2.9,2.3c-1.4,0.5-2.9,0.7-4.4,0.7s-2.9-0.2-4.3-0.7c-1.8-0.6-3.1-2-3.5-3.8
		C0.3,16.7,0,14.4,0,12s0.3-4.7,0.8-7.1c0.4-1.8,1.8-3.2,3.5-3.8c2.8-0.9,5.8-0.9,8.6,0C14.2,0.8,15.6,0.9,16.8,1.4z M5.1,20.5
		c2.3,0.8,4.8,0.8,7.2,0c0.9-0.3,1.6-1.1,1.9-1.9c0.5-2.2,0.8-4.4,0.8-6.6s-0.2-4.4-0.7-6.5C14,4.7,13.4,4,12.6,3.6
		c-0.1,0-0.2-0.1-0.4-0.1c-1.2-0.4-2.4-0.6-3.6-0.6S6.2,3.1,5.1,3.4c-1,0.3-1.7,1.1-1.9,2C2.7,7.6,2.4,9.8,2.4,12
		c0,2.2,0.2,4.4,0.7,6.5C3.4,19.5,4.1,20.2,5.1,20.5z M17,16.3c1.5-1.5,2.9-3.1,4-4.8c0.3-0.5,0.5-1,0.5-1.6c0-0.4-0.1-0.9-0.3-1.2
		c-1.1-2.1-2.9-3.8-5.2-4.9c0.2,0.4,0.3,0.8,0.4,1.2c0.5,2.3,0.8,4.7,0.8,7C17.3,13.4,17.2,14.9,17,16.3z M9.3,7.7
		C9.5,8,9.7,8.2,9.7,8.2c0,0,0.2,0.2,0.4,0.5c0.2,0.3,0.4,0.5,0.5,0.6c0,0,0.2,0.2,0.3,0.4c0.2,0.2,0.4,0.4,0.4,0.6
		c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.2,0.4s0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
		c0,0.1,0,0.3,0,0.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.2,0.4s-0.2,0.3-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1
		c-0.1,0-0.2-0.2-0.4-0.3l-0.2-0.2l0.2,0.9c0.1,0.5,0.2,0.9,0.2,0.9c0,0-0.1,0-0.8,0c-0.5,0-0.8,0-0.8,0c0,0,0.1-0.4,0.2-0.9
		c0.1-0.5,0.2-0.9,0.3-0.9c0,0-0.1,0-0.2,0.1C8.2,14,8.1,14.1,8,14.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0.1-0.4,0.1
		c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.3-0.3-0.4
		c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0-0.1,0.1-0.3,0.1-0.5c0.1-0.1,0.1-0.4,0.2-0.5
		s0.2-0.3,0.3-0.5c0.1-0.1,0.3-0.4,0.4-0.5C6.3,10,6.6,9.8,6.7,9.6c0.1-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.6-0.7,0.8-0.9
		c0.2-0.3,0.5-0.6,0.5-0.6c0-0.1,0.1-0.1,0.1-0.1S9,7.4,9.3,7.7z"/>
            </svg>
        </SvgIcon>
    );
}

export default LiveCasinoIcon;