import {ApiResponse} from "../apiResponseModels";
import {GameObj, GameProvider, OutcomeGameSession, ProviderGroup} from "./models/models";

export interface PlayGamesState {
    gameType: string | null
    providers: GameProvider[]|null
    providersFiltered: GameProvider[]|null // only providers which have games
    selectedProvider: string|null
    games: GameObj[]|null
    groupedGames: ProviderGroup[]|null // games grouped by provider
    gameSession: OutcomeGameSession
    sportUrl: string | null

    isProvidersOutcomeLoading: boolean
    isGamesLoading: boolean
    isGameSessionLoading: boolean
    isSportsBookLoading: boolean
}

export type SportType = 'live' | ''

export enum PlayGamesLoader {
    isProvidersOutcomeLoading,
    isOutcomeGameLoading,
    isGameSessionLoading,
    isSportsBookLoading
}

export interface iUsePlayGames extends PlayGamesState {
    getProviders: (onSuccess?: () => void) => void
    getGames: (providerName?: string) => void
    startGameSession: (gameId: number) => void
    openSportsBook: (extension: SportType) => void
}

export interface ShowProvidersResponse extends ApiResponse {
    providers?: GameProvider[]
}

export interface GetGamesResponse extends ApiResponse {
    games?: GameObj[]
}

export interface StartGameSessionResponse extends ApiResponse {
    SessionId?: string
    SessionUrl?: string
}

export interface OpenSportsBookResponse extends ApiResponse {
    url?: string
}