import React, {FC} from 'react';
import {Box, FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import { Lang } from "../../../features/localisation/useLanguageInterface";
import { useLanguage } from "../../../features/localisation/useLanguage";

import EnIcon from '../../../../assets/oropuroV2/langs/united-kingdom.png'
import EsIcon from '../../../../assets/oropuroV2/langs/spain.png'
import PtIcon from '../../../../assets/oropuroV2/langs/portugal.png'
import RuIcon from '../../../../assets/oropuroV2/langs/russia.png'
import DeIcon from '../../../../assets/oropuroV2/langs/germany.png'
import FrIcon from '../../../../assets/oropuroV2/langs/france.png'
import { Flag } from "./Flag";

const LanguageSelector: FC<{fullWidth?: boolean, shortMode?: boolean}> = ({fullWidth, shortMode = true}) => {
    const isDevMode = process.env.REACT_APP_ENVIRONMENT === "live"
    const { switchLanguage, languagePack: { lang } } = useLanguage();

    const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
        switchLanguage(event.target.value as Lang);
    };

    return (
        <FormControl
            fullWidth={fullWidth}
            size={'small'}
            variant={'outlined'}
        >
            <Select
                labelId="language-selector-label"
                id="language-selector"
                value={lang}
                onChange={handleLanguageChange}
                sx={{
                    borderWidth: '1px',
                    borderRadius: '12px',
                    '& .MuiSelect-outlined': {
                        padding: '6px 8px'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'grey.700',
                        borderWidth: '1px',
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            borderRadius: '12px',
                            '& .MuiMenuItem-root': {
                                color: 'grey.400',
                            },
                        },
                    },
                }}
            >
                <MenuItem value={Lang.EN}><Box display={'flex'}><Flag imageUrl={EnIcon}/>{!shortMode && 'English'}</Box></MenuItem>
                <MenuItem value={Lang.ES}><Box display={'flex'}><Flag imageUrl={EsIcon}/>{!shortMode && 'Español'}</Box></MenuItem>
                <MenuItem value={Lang.PT}><Box display={'flex'}><Flag imageUrl={PtIcon}/>{!shortMode && 'Português'}</Box></MenuItem>
                {isDevMode && <MenuItem value={Lang.RU}><Box display={'flex'}><Flag imageUrl={RuIcon}/>{!shortMode && 'Русский'}</Box></MenuItem>}
                {isDevMode && <MenuItem value={Lang.DE}><Box display={'flex'}><Flag imageUrl={DeIcon}/>{!shortMode && 'Deutsch'}</Box></MenuItem>}
                {isDevMode && <MenuItem value={Lang.FR}><Box display={'flex'}><Flag imageUrl={FrIcon}/>{!shortMode && 'Français'}</Box></MenuItem>}
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;
