import Typography from "@mui/material/Typography";

export const FooterDisclaimerContentEn = () => {
    return <>
        <Typography>
            Welcome to OroPuro, the premier online casino where excitement meets luxury! Offering a vast selection of high-quality games, from classic slots to live dealer tables, OroPuro ensures that every player can enjoy an unparalleled gaming experience. Our platform is designed to provide seamless navigation, fast payouts, and top-tier security, making it the perfect choice for both novice and seasoned players.
        </Typography>
        <Typography variant={'h4'}>
            Why Choose OroPuro?
        </Typography>
        <ul>
            <li>Wide Range of Games: <span>Explore hundreds of thrilling slots, table games, and live casino options from leading software providers.</span></li>
            <li>Safe and Secure: <span>Play with confidence, knowing that your personal and financial information is protected with the latest encryption technologies.</span></li>
            <li>Fast Payouts: <span>Enjoy quick withdrawals with multiple trusted payment methods.</span></li>
            <li>Exclusive Bonuses: <span>Take advantage of generous welcome bonuses, promotions, and loyalty rewards.</span></li>
            <li>Mobile-Friendly: <span>Access OroPuro anytime, anywhere, on your desktop, tablet, or smartphone.</span></li>
        </ul>
        <Typography>
            Whether you're here to spin the reels, try your hand at poker, or experience the thrill of live roulette, OroPuro delivers top-notch entertainment in a secure, user-friendly environment. Join us today and start winning big at OroPuro Online Casino!
        </Typography>
    </>
}