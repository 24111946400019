import {FC} from "react";
import {Drawer} from "@mui/material";
import {SideMenu} from "./SideMenu";

export const SideMenuDrawer: FC<{opened: boolean, onClose: (opened: boolean) => void}> = ({opened, onClose}) => {
    return <Drawer
        onClose={() => onClose(false)}
        sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: 260,
                boxSizing: 'border-box',
            },
        }}
        open={opened}
    >
        <SideMenu/>
    </Drawer>
}