import {Box, Button, Dialog} from "@mui/material";
import CashbackBg from '../../../assets/oropuro_redesigned_v2/cashback-bg.webp'
import Typography from "@mui/material/Typography";
import CashbackBag from '../../../assets/oropuro_redesigned_v2/cashback-bag.webp'
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import SlotCounter from 'react-slot-counter';
import {useLanguage} from "../../features/localisation/useLanguage";
import {useUser} from "../../features/user/useUser";
import { useAppDispatch } from "../../store";
import {clearCashback} from "../../features/user/userSlice";
import {convertCentsToCurrencyString} from "../../features/common_funcs";

export const CashbackPopup = () => {
    const { smallScreen: sm } = useCommonMediaQueries()
    const { languagePack: {pack: {argentina: {cashback: lang}}} } = useLanguage()
    const { cashback } = useUser()
    const dispatch = useAppDispatch()

    return <Dialog
        open={Boolean(cashback)}
        maxWidth={'sm'}
        fullWidth
        sx={theme => ({
            '& .MuiDialog-paper': {
                borderRadius: theme.spacing(2),
                minHeight: '300px',
                backgroundImage: `url(${CashbackBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                color: theme.palette.primary.dark,
                justifyContent: 'center',
                alignItems: 'center',
                p: 4,
                boxShadow: `0 0 10vw ${theme.palette.primary.dark}`
            }
        })}
    >
        <Typography
            sx={theme => ({
                fontSize: theme.typography.pxToRem(38),
                fontWeight: 800,
                textTransform: "uppercase",
                textAlign: "center",
                background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main}, ${theme.palette.primary.light}, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                lineHeight: 1
            })}
        >
            {lang.title}
        </Typography>

        <Typography
            sx={theme => ({
                fontSize: theme.typography.pxToRem(38),
                fontWeight: 800,
                textTransform: "uppercase",
                textAlign: "center",
                background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main}, ${theme.palette.primary.light}, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                lineHeight: 1
            })}
        >
            <SlotCounter value={cashback ? convertCentsToCurrencyString(cashback) : ''} />
        </Typography>
        <Box
            width={'100%'}
            height={'200px'}
            sx={{
                backgroundImage: `url(${CashbackBag})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        />
        <Button
            onClick={() => dispatch(clearCashback())}
            variant={'contained'}
            sx={{
                position: 'absolute',
                bottom: '20px',
                right: sm ? undefined : '20px'
            }}
        >
            {lang.gotItBTN}
        </Button>
    </Dialog>
}