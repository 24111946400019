import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const LiveInPlayIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" fillRule={'evenodd'} clipRule={'evenodd'} d="M17.8,6.1h-3.9l3.9-3.5c0.4-0.3,0.4-0.9,0.1-1.2C17.5,1,17,1,16.6,1.3l-4.7,4.2L7.4,1.3
		C7,1,6.5,1,6.1,1.4c-0.3,0.3-0.3,0.9,0,1.2L10,6.1H6.2C3.3,6.1,1,8.4,1,11.3v6.3c0,2.9,2.4,5.2,5.3,5.2h11.5c2.9,0,5.3-2.4,5.3-5.2
		v-6.3C23,8.4,20.7,6.1,17.8,6.1z M17.6,17c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.9c0-0.2,0.1-0.4,0.2-0.5
		c0.1-0.1,0.3-0.2,0.5-0.2h2.6c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.4,0.1h-1.9v1
		H20c0.2,0,0.3,0,0.4,0.1s0.1,0.2,0.1,0.4c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.4,0.1h-1.8v1.1h1.9c0.2,0,0.3,0,0.4,0.1
		c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.4,0.1H17.6z M12.8,16.9C12.9,17,13,17,13.2,17
		c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.4l1.8-4c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
		c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l-1.3,3.1l-1.3-3.1c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.1-0.4-0.1
		c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.3,0.1,0.5l1.8,4C12.5,16.7,12.6,16.8,12.8,16.9z M8.8,17
		c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-4c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2
		c0.1,0.1,0.2,0.3,0.2,0.5v4c0,0.2,0,0.4-0.2,0.5C9.2,17,9,17,8.8,17z M3.5,16.8C3.6,16.9,3.7,17,4,17h2.5c0.2,0,0.3-0.1,0.4-0.2
		c0.1-0.1,0.2-0.2,0.2-0.4s0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2H4.6v-3.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2
		c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v3.9C3.3,16.5,3.3,16.7,3.5,16.8z"/>
            </svg>
        </SvgIcon>
    );
}

export default LiveInPlayIcon;