import React, { FC, useEffect, useRef } from "react";
import { TopGameHolder } from "./models/models";
import { Stack } from "@mui/material";
import { TopGameCard } from "./TopGameCard";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface TopGamesListMobileProps {
    gamesList: TopGameHolder[]
    onGameClick?: (game: TopGameHolder) => () => void
}

export const TopGamesListMobile: FC<TopGamesListMobileProps> = ({ gamesList, onGameClick = () => () => {} }) => {
    const topRowRef = useRef<HTMLDivElement | null>(null);
    const bottomRowRef = useRef<HTMLDivElement | null>(null);
    const animationFrameRef = useRef<number | null>(null);
    const { xxSmallScreen, smallScreen } = useCommonMediaQueries()

    const gap = xxSmallScreen ? '8px' : smallScreen ? '14px' : '20px'

    const topDirectionRef = useRef<'forward' | 'backward'>('forward');
    const bottomDirectionRef = useRef<'forward' | 'backward'>('backward');



    const launchAnimation = () => {
        if (topRowRef.current && bottomRowRef.current) {
            const scrollStep = 1;
            const topScrollWidth = topRowRef.current.scrollWidth;
            const bottomScrollWidth = bottomRowRef.current.scrollWidth

            const scroll = () => {
                if (topRowRef.current && bottomRowRef.current) {
                    if (topDirectionRef.current === 'forward') {
                        topRowRef.current.scrollLeft += scrollStep;
                        if (topRowRef.current.scrollLeft + topRowRef.current.clientWidth >= topScrollWidth) {
                            topDirectionRef.current = 'backward';
                        }
                    } else {
                        topRowRef.current.scrollLeft -= scrollStep;
                        if (topRowRef.current.scrollLeft <= 0) {
                            topDirectionRef.current = 'forward';
                        }
                    }

                    if (bottomDirectionRef.current === 'backward') {
                        bottomRowRef.current.scrollLeft += scrollStep;
                        if (bottomRowRef.current.scrollLeft + bottomRowRef.current.clientWidth >= bottomScrollWidth) {
                            bottomDirectionRef.current = 'forward';
                        }
                    } else {
                        bottomRowRef.current.scrollLeft -= scrollStep;
                        if (bottomRowRef.current.scrollLeft <= 0) {
                            bottomDirectionRef.current = 'backward';
                        }
                    }

                    animationFrameRef.current = requestAnimationFrame(scroll);
                }
            }

            scroll();
        }
    }

    useEffect(() => {
        if (bottomRowRef.current) {
            bottomRowRef.current.scrollLeft = bottomRowRef.current.scrollWidth;
        }

        launchAnimation();

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, []);

    return (
        <Stack gap={gap} onTouchStart={() => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current)
            }
        }}>
            <Stack direction={'row'}
                   sx={{
                       overflowX: 'auto',
                       '&::-webkit-scrollbar': { display: 'none' },
                       '-ms-overflow-style': 'none',  /* IE and Edge */
                       'scrollbar-width': 'none'  /* Firefox */
                   }}
                   gap={'5px'} ref={topRowRef}>
                {gamesList.length > 0 && gamesList.slice(0, Math.ceil(gamesList.length / 2)).map((game, index) => (
                    <TopGameCard mobileMode game={game} key={`row-1-${index}-${game.gameID}`} onClick={onGameClick(game)} />
                ))}
            </Stack>
            <Stack direction={'row'}
                   sx={{
                       overflowX: 'auto',
                       '&::-webkit-scrollbar': { display: 'none' },
                       '-ms-overflow-style': 'none',  /* IE and Edge */
                       'scrollbar-width': 'none'  /* Firefox */
                   }}
                   gap={'5px'} ref={bottomRowRef}>
                {gamesList.length > 0 && gamesList.slice(Math.ceil(gamesList.length / 2), gamesList.length).map((game, index) => (
                    <TopGameCard mobileMode game={game} key={`row-2-${index}-${game.gameID}`} onClick={onGameClick(game)} />
                ))}
            </Stack>
        </Stack>
    );
};
