import { FC } from "react";
import {alpha, Box, Stack} from "@mui/material";
import { DecoratedTitle } from "../decorated_title/DecoratedTitle";
import { TopGameHolder } from "./models/models";
import { TopGamesList } from "./TopGamesList";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import TreasureChest from '../../../../assets/oropuroV2/top_games/top_games_chest.webp'
import CornerLT from '../../../../assets/oropuroV2/top_games/corner_LT.png'
import CornerLB from '../../../../assets/oropuroV2/top_games/corner_LB.png'
import CornerRT from '../../../../assets/oropuroV2/top_games/corner_RT.png'
import CornerRB from '../../../../assets/oropuroV2/top_games/corner_RB.png'
import { useLanguage } from "../../../features/localisation/useLanguage";

export const TopGamesSection: FC = () => {
    const { languagePack: { pack: { argentina: { common: lang } } } } = useLanguage()
    const { largeScreen } = useCommonMediaQueries()

    const domain = window.location.hostname;
    console.log(domain);

    return <Box>
        <Box position={'relative'} pt={'27px'}>
            <Box position={'absolute'} top={0} left={'50%'} sx={{ transform: 'translateX(-50%)' }}>
                <DecoratedTitle>{lang.topGames}</DecoratedTitle>
            </Box>

            <Stack
                direction={'row'}
                py={'36px'}
                px={largeScreen ? 0 : '2vw'}
                sx={theme => (largeScreen ? {
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.primary.main,
                    backgroundImage: alpha('#000', 0.7),
                    backgroundPosition: `center`,
                    backgroundSize: `auto`,
                    backgroundRepeat: 'no-repeat',
                } : {
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.primary.main,
                    backgroundImage: `url(${CornerLT}), url(${CornerLB}), url(${CornerRT}), url(${CornerRB}), linear-gradient(#22242A, ${alpha('#000', 0.7)}`,
                    backgroundPosition: `top left, bottom left, top right, bottom right, center`,
                    backgroundSize: `301px 22px, 301px 22px, 301px 22px, 301px 22px, auto`,
                    backgroundRepeat: 'no-repeat',
                })}
                borderRadius={'20px'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={'2vw'}
            >
                {!largeScreen && <Box
                    width={'20vw'}
                    sx={{
                        aspectRatio: '1/1',
                    }}
                    position={'relative'}
                    overflow={'visible'}
                >
                    <Box
                        top={'-2vw'}
                        left={'-2vw'}
                        right={'-2vw'}
                        bottom={'-2vw'}
                        position={'absolute'}
                        sx={{
                            backgroundImage: `url(${TreasureChest})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}
                    />
                </Box>}
                <Box
                    width={largeScreen ? '100%' : '60vw'}
                >
                    <TopGamesList gamesList={topGamesListProduction}/>
                </Box>
            </Stack>
        </Box>


    </Box>
}

const topGamesListProduction: TopGameHolder[] = [
    { gamePic: '/cdn/game_img/sqr_mid/galaxsys.ninja_crash.jpg', gameID: 3854 },
    { gamePic: '/cdn/game_img/sqr_mid/pgsoft.fortune_tiger.jpg', gameID: 3367 },
    { gamePic: '/cdn/game_img/sqr_mid/pragmatic.jokers_jewels.gif', gameID: 2915 },
    { gamePic: '/cdn/game_img/sqr_mid/3oaks.big_heist.jpg', gameID: 941 },
    { gamePic: '/cdn/game_img/sqr_mid/bgaming.FruitMillion.webp', gameID: 7831 },
    { gamePic: '/cdn/game_img/sqr_mid/relax.money_train_3.jpg', gameID: 1686 },
    { gamePic: '/cdn/game_img/sqr_mid/pragmatic.gates_of_olympus.gif', gameID: 3010 },
    { gamePic: '/cdn/game_img/sqr_mid/barbarabang.Doodle_Crash.webp', gameID: 6974 },
    { gamePic: '/cdn/game_img/sqr_mid/3oaks.egypt_fire.jpg', gameID: 187 },
    { gamePic: '/cdn/game_img/sqr_mid/bgaming.WildTiger.webp', gameID: 7844 },
    { gamePic: '/cdn/game_img/sqr_mid/mancala.mancala_quest.jpg', gameID: 3764},
    { gamePic: '/cdn/game_img/sqr_mid/pragmatic.great_rhino.jpg', gameID: 2916 },
]