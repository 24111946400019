import {LatestWinningsTableView} from "./LatestWinningsTableView";
import {LatestWinningsTableItemView} from "./LatestWinningsTableItemView";
import {useTopWins} from "../../features/top_wins/useTopWins";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../../store";
import {resetTopWins} from "../../features/top_wins/topWinsSlice";

export const LatestWinningsTable = () => {
    const dispatch = useAppDispatch();
    const {getTopWins, topWins, isTopWinsLoading} = useTopWins()
    const [justMounted, setJustMounted] = useState<boolean>(true)

    useEffect(() => {
        if (justMounted && topWins === null && !isTopWinsLoading) {
            setJustMounted(false)
            getTopWins()
        }
    }, [getTopWins, isTopWinsLoading, justMounted, topWins]);

    // useEffect(() => {
    //     return () => {
    //         dispatch(resetTopWins())
    //     }
    // }, [dispatch]);

    return <LatestWinningsTableView>
        {topWins && topWins.map((item) => <LatestWinningsTableItemView winObject={item} key={item.uuid}/>)}
    </LatestWinningsTableView>
}