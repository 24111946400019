import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { InputAdornment, LinearProgress, Modal, Paper } from "@mui/material";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import SearchIcon from "@mui/icons-material/Search";
import {getMediaCDN} from "../../config";
import Typography from "@mui/material/Typography";
import {GameObj} from "../../features/play_games/models/models";
import {GameCard} from "./games/GameCardV2";
import {Link} from "react-router-dom";
import {useLanguage} from "../../features/localisation/useLanguage";

const minSearchTextLength = 3

interface GameSearchWindowProps {
    onClose: () => void
}

export const GameSearchWindow: FC<GameSearchWindowProps> = ({ onClose }) => {
    const {languagePack: {pack: {gameSearch: lang}}} = useLanguage()
    const { xxSmallScreen, largeScreen } = useCommonMediaQueries()
    const { isGameSessionLoading, games } = usePlayGames()
    const [ searchText, setSearchText ] = useState<string>('')
    const [ foundGames, setFoundGames ] = useState<GameObj[]>([])
    const scrollRef = useRef<HTMLDivElement>(null);
    const isWheelListenerAttached = useRef(false); // Флаг для отслеживания установки обработчика
    const cdn = getMediaCDN()

    useEffect(() => {
        const element = scrollRef.current;

        const onWheel = (event: WheelEvent) => {
            if (event.deltaY !== 0 && element) {
                event.preventDefault();
                // noinspection JSSuspiciousNameCombination
                element.scrollLeft += event.deltaY;
            }
        };

        if (element && !isWheelListenerAttached.current) {
            element.addEventListener('wheel', onWheel);
            isWheelListenerAttached.current = true;
        }

        return () => {
            if (element && isWheelListenerAttached.current) {
                element.removeEventListener('wheel', onWheel);
                isWheelListenerAttached.current = false;
            }
        };
    }, [foundGames]);

    const clickHandler = () => {
        onClose()
    }

    const handleSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setSearchText(value)
        if (games !== null) {
            if (value.trim().length >= minSearchTextLength) {
                const filteredGames = games.filter(game => game.Name.toLowerCase().includes(value.toLowerCase()))
                setFoundGames(filteredGames)
            } else {
                setFoundGames([])
            }
        }
    }

    return <Modal
        sx={{
            backgroundColor: 'rgba(65,65,65,0.35)',
            backdropFilter: 'blur(2px)',
        }}
        open={true}
        onClose={onClose}
    >
        { xxSmallScreen ?
            <Stack
                position={'fixed'}
                top={0}
                left={0}
                right={0}
                sx={theme => ({
                    backgroundColor: theme.palette.background.paper
                })}
            >
                <TextField
                    sx={{
                        '& .MuiInput-input': {
                            padding: '14px'
                        },
                        '& .MuiInputAdornment-root': {
                            padding: '14px',
                        },
                    }}
                    variant={'standard'}
                    value={searchText}
                    onChange={handleSearchTextChanged}
                    placeholder={'Type game name'}
                    slotProps={{
                        input: {
                            endAdornment: <InputAdornment position="end">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                        }
                    }}
                />
                <Stack sx={{overflowY: 'auto'}} maxHeight={'40vh'}>
                    <Stack gap={'8px'}>
                        {foundGames.map(game => <Stack
                            component={Link}
                            to={`/launch_game?internalId=${game.internal_id}`}
                            sx={{cursor: 'pointer'}} px={'14px'}
                                                       direction={'row'}
                                                       key={game.internal_id}
                                                       onClick={clickHandler}
                                                       gap={'8px'} alignItems={'center'}>
                            <GameCard
                                width={'50px'}
                                cdn={cdn ?? `/cdn`}
                                game={game}
                            />
                            <Typography fontSize={'20px'} sx={{userSelect: 'none'}}>
                                {game.Name}
                            </Typography>
                        </Stack>)}
                    </Stack>
                </Stack>
            </Stack>
            :
            <Paper
                sx={{
                    transform: 'translate(-50%, -50%)',
                    outline: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    minWidth: '700px',
                    width: largeScreen ? '80vw' : '55vw',
                    padding: '12px',
                    borderRadius: '20px'
                }}
            >
                <Stack gap={'8px'}>
                    <TextField
                        disabled={isGameSessionLoading}
                        value={searchText}
                        onChange={handleSearchTextChanged}
                        placeholder={lang.placeholder}
                        variant={'outlined'}
                        slotProps={{
                            input: {
                                endAdornment: <InputAdornment position="end">
                                    <SearchIcon color={'primary'}/>
                                </InputAdornment>
                            }
                        }}
                    />
                    {isGameSessionLoading && <LinearProgress/>}
                    <Stack
                        width={'100%'}
                        ref={scrollRef}
                        direction={'row'}
                        gap={'6px'}
                        pb={'2px'}
                        sx={{
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#15181D #15181D60',
                        }}>
                        {foundGames.map(game => <GameCard
                            width={'100px'}
                            key={game.internal_id}
                            cdn={cdn ?? '/cdn'}
                            game={game}
                            onGameClick={clickHandler}
                        />)}
                    </Stack>
                </Stack>
            </Paper>}
    </Modal>
}