import React, { FC, useEffect } from "react";
import { DashboardPageLayout } from "../../../components/oropuro_layout/dashboard_content/DashboardPageLayout";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTransactions } from "../../../features/transactions/useTransactions";
import { PageSizeSwitcher } from "../../../components/oropuro_layout/pages/page_size_switcher/PageSizeSwitcher";
import { getNumberParam } from "../../../features/common_funcs";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { useUser } from "../../../features/user/useUser";
import { TransactionItem } from "../../../components/oropuro_layout/transactions/TransactionItem";
import { Paginator } from "../../../components/common/paginator/Paginator";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { TransactionsTable } from "../../../pages/DashboardV2/transactions/TransactionsTable";
import { useLanguage } from "../../../features/localisation/useLanguage";
import {setTransactionsPageSize} from "../../../features/transactions/transactionsSlice";
import {PageLayout} from "../../../components/oropuro_layout/PageLayout";
import {SectionDividerSimple} from "../../../components/oropuro_layout/section_devider/SectionDividerSimple";
import {DashboardMobileTitle} from "../../../components/oropuro_layout/dashboard_mobile_title/DashboardMobileTitle";

export const TransactionsPage: FC = () => {
    const { languagePack: { pack: { argentina: { dashboard: { sideMenu: menuLang} } } } } = useLanguage()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const { pageSize, currentPage, getTransactions, transactions, clearTransactions, totalPages } = useTransactions()
    const { user, token } = useUser()
    const { mediumScreenMin: mdMin, mediumScreen: md } = useCommonMediaQueries()

    useEffect(() => {
        return () => {
            clearTransactions()
        }
    }, [ clearTransactions ])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const page: number|null = getNumberParam(searchParams.get('page'))
        const itemsOnPage: number|null = getNumberParam(searchParams.get('pageSize'))
        if ((itemsOnPage && itemsOnPage !== pageSize) && (page && page !== currentPage)) {
            getTransactions(page, itemsOnPage)
        } else if ((itemsOnPage && itemsOnPage !== pageSize) && transactions.length === 0) {
            getTransactions(1, itemsOnPage)
        } else if (itemsOnPage && itemsOnPage !== pageSize) {
            dispatch(setTransactionsPageSize(itemsOnPage))
            getTransactions(1, itemsOnPage)
        } else if (page && page !== currentPage) {
            getTransactions(page)
        } else if (transactions.length === 0) {
            getTransactions(1)
        } else if (!Boolean(itemsOnPage) && !Boolean(page) && (pageSize !== 30 || currentPage !== 1) && transactions.length !== 0) {
            getTransactions(1, 30)
        }
    }, [currentPage, transactions, dispatch, getTransactions, location.search, pageSize])

    return <PageLayout pageName={menuLang.transactions} carouselDisabled>
        {mdMin ? <SectionDividerSimple mt={2}>{menuLang.transactions}</SectionDividerSimple> : <DashboardMobileTitle title={menuLang.transactions}/>}

        {user &&  token && <Stack padding={'16px'} gap={'12px'}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography fontSize={'28px'}>
                    {menuLang.transactions}
                </Typography>
                <PageSizeSwitcher pageSize={pageSize}/>
            </Stack>
            {md ? transactions.map(transaction => <TransactionItem currency={user?.user_currency}
                                                                             key={transaction.uuid}
                                                                             transactionItem={transaction}/>)
                : <TransactionsTable/>}
            <Stack direction={'row'} justifyContent={'end'}>
                <Paginator totalPages={totalPages} currentPage={currentPage}/>
            </Stack>
        </Stack>}
    </PageLayout>
}