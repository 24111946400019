import React, {FC} from "react";
import {SectionDividerProps} from "./SectionDivider.interface";
import {alpha, Box, Button, ButtonProps, useTheme} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const SectionDivider: FC<SectionDividerProps> = (props) => {
    const { languagePack: { pack: { argentina: { common: lang } } } } = useLanguage()
    const {title, onLeftButtonClick, onRightButtonClick, leftButtonDisabled, rightButtonDisabled, link, onClick, icon, showLeftRightButtons = true, customButtonName} = props
    const isIconComponent =
        icon && (typeof icon === 'function' || typeof icon === 'object') && !React.isValidElement(icon);
    const Icon = icon !== undefined && isIconComponent ? icon : undefined
    const iconUrl = icon !== undefined && typeof icon === 'string' ? icon : undefined
    const { smallScreen: sm} = useCommonMediaQueries()
    const theme = useTheme()

    const commonButtonProps: ButtonProps = {
        variant: 'outlined',
        sx: {
            minWidth: '34px',
            height: '34px',
            px: theme.spacing(1),
            backgroundColor: alpha('#fff', 0.08),
            borderWidth: '1px',
            borderColor: alpha('#fff', 0.1),
            color: '#E4DCD5',
            '&:hover': {
                borderWidth: '1px',
            }
        }
    };

    return <Box
        sx={theme => ({
            pl: 2,
            // backgroundColor: '#6e0360',
        })}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
    >
        <Box
            display={'flex'}
            alignItems={'center'}
            gap={sm ? 1 : 2}
        >
            {Icon && <Icon sx={{fontSize: theme.typography.pxToRem(sm ? 24 : 28), color: theme.palette.primary.main}}/>}
            {iconUrl && <Box
                sx={{
                    backgroundImage: `url(${iconUrl})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: sm ? '20px' : '28px',
                    aspectRatio: '1/1'
                }}
            />}
            <Typography
                fontSize={theme.typography.pxToRem(sm ? 20 :24)}
            >
                {title}
            </Typography>
        </Box>

        <Box
            display={'flex'}
            alignItems={'center'}
            gap={0.3}
        >
            {showLeftRightButtons && (
                <Button {...commonButtonProps} disabled={leftButtonDisabled} onClick={onLeftButtonClick}>
                    <ArrowBackIosIcon sx={{mr: '-8px', fontSize: '16px'}}/>
                </Button>
            )}
            {showLeftRightButtons && (
                <Button {...commonButtonProps} disabled={rightButtonDisabled} onClick={onRightButtonClick}>
                    <ArrowForwardIosIcon sx={{mr: '-4px', fontSize: '16px'}}/>
                </Button>
            )}
            {(link || onClick) && <Button
                {...commonButtonProps}
                {...(link !== undefined ? {component: Link, to: link} : {})}
                endIcon={<ArrowForwardIosIcon sx={{fontSize: '16px'}}
                />}
                onClick={onClick}
            >
                {customButtonName ?? lang.seeAllBTN}
            </Button>}
        </Box>
    </Box>
}