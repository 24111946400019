import React, {FC} from "react";
import {Box} from "@mui/material";
import {CarouselSliderProps} from "./CarouselSlider.interface";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import {Link} from "react-router-dom";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const CarouselSlider: FC<CarouselSliderProps> = ({sliders}) => {
    const {smallScreen: sm} = useCommonMediaQueries()

    return <Box
        width={'100%'}
        mt={2}
    >
        <Carousel
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            showStatus={false}
            interval={6000}
        >
            {sliders.map((slide, i) => <Box
                width={'100%'}
                key={`${slide.link}-${i}`}
                // component={slide.link !== undefined ? Link : undefined}
                // {...(slide.link !== undefined ? {to: slide.link} : {})}
                {...(slide.link !== undefined ? {to: slide.link, component: Link} : {})}
            >
                <Box
                    width={'100%'}
                    sx={{
                        aspectRatio: sm ? '2.38/1' : '3.95/1',
                        backgroundImage: `url(${sm ? slide.mobilePicUrl : slide.desktopPicUrl})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        cursor: 'pointer',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
            </Box>)}
        </Carousel>
    </Box>
}