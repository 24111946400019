import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const SearchIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M18,16.6l4.3,4.3l-1.4,1.4L16.6,18C15,19.3,13,20,11,20c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9
		C20,13,19.3,15,18,16.6z M16,15.9c1.3-1.3,2-3.1,2-4.9c0-3.9-3.1-7-7-7c-3.9,0-7,3.1-7,7c0,3.9,3.1,7,7,7c1.8,0,3.6-0.7,4.9-2
		L16,15.9z"/>
            </svg>
        </SvgIcon>
    );
}

export default SearchIcon;