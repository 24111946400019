import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const FastGamesIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill={'currentColor'} d="M6.2,22.6c0.6-0.6,1-1.5,1-2.4c0-0.9-0.4-1.7-1-2.4c-0.6-0.6-1.5-1-2.4-1c-0.9,0-1.7,0.3-2.4,1
		c-0.9,0.9-1.3,2.4-1.4,3.7C0,22.2,0,23,0,23.7c0,0.2,0.2,0.2,0.2,0.2h0c0.1,0,1.1,0,1.5-1c0.1-0.2,0.1-0.4,0.3-0.6
		c0.2-0.3,0.7-0.1,0.7,0.3c0,0.4,0,0.8,0.2,1.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0C4.3,23.7,5.4,23.4,6.2,22.6z M17.1,18.7v-2.9
		c0.4-0.3,0.7-0.6,1-0.9l0.9-0.9c3.5-3.5,5.1-7.9,4.9-11.9c-0.1-1.2-0.9-2-2.1-2.1c-4-0.2-8.4,1.5-11.9,4.9L9.1,5.9
		c-0.3,0.3-0.6,0.7-0.9,1H5.3c-0.6,0-1.2,0.4-1.5,0.9l-1.7,3.4C2,11.3,2,11.4,2,11.6c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.2,0.6,0.2
		H5c-0.3,0.9-0.1,1.9,0.5,2.5l3.7,3.7c0.6,0.6,1.5,0.8,2.5,0.5v2.2c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.4,0.2,0.6,0.2
		c0.1,0,0.3-0.1,0.4-0.1l3.4-1.7C16.8,19.9,17.1,19.4,17.1,18.7z M15.1,8.9c-0.5-0.5-0.8-1.1-0.9-1.8c-0.1-0.7-0.1-1.4,0.2-2
		s0.7-1.2,1.3-1.5C16.2,3.2,16.9,3,17.6,3s1.4,0.2,1.9,0.6c0.6,0.4,1,0.9,1.3,1.5s0.3,1.3,0.2,2c-0.1,0.7-0.5,1.3-0.9,1.8
		c-0.3,0.3-0.7,0.6-1.1,0.8S18,9.9,17.6,9.9c-0.4,0-0.9-0.1-1.3-0.3C15.8,9.5,15.4,9.2,15.1,8.9z"/>
            </svg>
        </SvgIcon>
    );
}

export default FastGamesIcon;