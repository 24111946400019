import { Box, Stack } from "@mui/material";
import { FC } from "react";
import {getMediaCDN} from "../../config";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
// import { GameCard } from "../oropuro_layout/games/GameCard";
import {GameObj} from "../../features/play_games/models/models";
import {GameCard} from "../oropuro_layout/games/GameCardV2";

interface GamesListProps {
    games: GameObj[]
    onGameClick?: (game: GameObj) => void
}

export const FullGamesList: FC<GamesListProps> = ({games, onGameClick}) => {
    const cdn = getMediaCDN();
    const { xxSmallScreen, smallScreen } = useCommonMediaQueries()
    const width = xxSmallScreen ? '30vw' : smallScreen ? '23vw' : '10vw'
    const gap = smallScreen ? '2vw' : '1vw'

    const itemsPerRow = xxSmallScreen ? 3 : smallScreen ? 4 : 9; // Количество элементов в ряду
    const remainingItems = itemsPerRow - (games.length % itemsPerRow);

    // Создаем массив пустых блоков
    const emptyBlocks = Array.from({ length: remainingItems }, (_, index) => (
        <Box key={`empty-${index}`} minWidth={width} />
    ));

    return <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'} rowGap={gap}>
        {games.map(game => <GameCard width={width} key={game.internal_id} cdn={cdn ?? ""} game={game} onGameClick={onGameClick}/>)}
        {emptyBlocks}
    </Stack>
}