import {FC} from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {LatestWinningsTableViewProps} from "./LatestWinnings.interface";
import {LatestWinningsTableItemView} from "./LatestWinningsTableItemView";
import {useLanguage} from "../../features/localisation/useLanguage";

export const LatestWinningsTableView: FC<LatestWinningsTableViewProps> = (props) => {
    const {children} = props
    const { languagePack: { pack: { argentina: {topWins: lang} } } } = useLanguage()

    return <TableContainer>
        <Table>
            <TableHead>
                <LatestWinningsTableItemView winObject={{
                    game_name: lang.game,
                    time: lang.date,
                    user_name: lang.user,
                    withdraw_sum: lang.betAmount,
                    multiplier: lang.multiplier,
                    deposit_sum: lang.payout,
                }} mode={'header'}/>
            </TableHead>
            <TableBody>
                {children}
            </TableBody>
        </Table>
    </TableContainer>
}