import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const SevenIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5,10.9V2.5c1.4,1,1.9,0.7,2.9,0.3C7.1,2.5,8.1,2.1,9.6,2c1.6-0.1,2.8,0.4,4,0.8
	c1.6,0.6,3.2,1.2,5.6,0.3C20.8,2.5,20.8,3.9,20,5c-1.9,3.2-3,4.7-4.5,8.3s-1,7.7-1,7.7s0,1-1,1H8.2c-0.7,0-0.7-1-0.7-1
	c-0.1-6,2-7.7,4.4-9.6c1.4-1,2.9-2.2,4.3-4.3c-4.2,2.1-6.7,1.1-8.1,0.6C7.6,7.4,7.2,7.3,7,7.4c-1,0.4-1.8,1.3-2.5,3.5H3.5z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
}

export default SevenIcon;