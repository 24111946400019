import {FC} from "react";
import TableRow from "@mui/material/TableRow";
import {LatestWinHeader} from "./LatestWinnings.interface";
import TableCell from "@mui/material/TableCell";
import {alpha, Box, Stack} from "@mui/material";
import {getMediaCDN} from "../../config";
import {convertCentsToCurrencyString} from "../../features/common_funcs";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import Typography from "@mui/material/Typography";
import {TopWinGame} from "../../features/top_wins/models/model";

export const LatestWinningsTableItemView: FC<{
    winObject: TopWinGame | LatestWinHeader, mode?: 'header' |
        'body'
}> = ({winObject, mode = 'body'}) => {
    const {smallScreen: sm} = useCommonMediaQueries()
    const cdn = getMediaCDN()
    const headerMode = mode === 'header'
    const bodyRow: TopWinGame | undefined = headerMode ? undefined : winObject as TopWinGame
    const headerRow: LatestWinHeader | undefined = headerMode ? winObject as LatestWinHeader : undefined
    const picUrl: string | undefined = !headerMode && bodyRow ? bodyRow.picUrl !== '' ? `${cdn}/game_img/sqr_mid/${bodyRow.picUrl}` : undefined : undefined

    return <TableRow>
        <TableCell align={'left'}>

                <Box display={'flex'} gap={1} alignItems={'center'}>
                    {picUrl && <Box
                        height={'30px'}
                        borderRadius={'7px'}
                        sx={{
                            aspectRatio: '1/1',
                            backgroundImage: `url(${picUrl})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />}
                    <Stack>
                    {(!sm || headerMode) && winObject.game_name}
                        {sm && !headerMode && <>
                            {winObject.user_name}
                            <Typography fontSize={'10px'} sx={theme => ({color: theme.palette.primary.main})}>
                                {winObject.game_name}
                        </Typography>
                        </>}
                    </Stack>
                </Box>



        </TableCell>
        {!sm && <>
            <TableCell align={'center'} sx={{color: !headerMode ? alpha('#E4DCD5', 0.5) : undefined}}>
                {winObject.time}
            </TableCell>
            <TableCell align={'center'}>
                {winObject.user_name}
            </TableCell>
            <TableCell align={'center'}>
                {headerRow ? headerRow.withdraw_sum : bodyRow ? `${convertCentsToCurrencyString(bodyRow.withdraw_sum)} ARS` : ''}
            </TableCell>
            <TableCell align={'center'}>
                {!headerMode ? <Box
                    width={'100%'}
                    borderRadius={'10px'}
                    sx={theme => ({
                        p: theme.spacing(0.5, 1),
                        backgroundColor: alpha(theme.palette.primary.dark, 0.2),
                        color: theme.palette.primary.main
                    })}>
                    {winObject.multiplier}
                </Box> : winObject.multiplier}
            </TableCell>
        </>}

        <TableCell align={'right'}
                   sx={{color: !headerMode ? bodyRow && bodyRow.deposit_sum > 0 ? '#0ACF00' : alpha('#E4DCD5', 0.5) : undefined}}>
            {headerRow ? headerRow.deposit_sum : bodyRow ? `${convertCentsToCurrencyString(bodyRow.deposit_sum)} ARS` : ''}
        </TableCell>
    </TableRow>
}