import { FC } from "react";
import { GameCardProps } from "./GameCard.interface";
import {GameCardView} from "./GameCardView";

export const GameCard: FC<GameCardProps> = (props) => {
    const { width, game, onGameClick, cdn, mobileMode = false } = props;
    const picUrl = `${cdn}/game_img/sqr_mid/${game.Pic}`;

    return <GameCardView
        gameLink={!props.disableLink ? `/launch_game?internalId=${game.internal_id}` : undefined}
        picUrl={picUrl}
        onClick={() => onGameClick?.(game)}
        title={game.Name}
        {...{width, mobileMode}}
    />
};
