import Axios, { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { useAppSelector } from "../../store";
import { ApiResponse } from "../apiResponseModels";

type Fields = Record<string, string | number | undefined | null>;

export const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
        'Content-type': `multipart/form-data;`,
    },
    responseType: 'json',
});

const prepareRequestData = (token: string, action: string, fields: Fields = {}) => {
    const data = new FormData();
    data.append('action', action);

    if (token) {
        data.append('token', token);
    }

    Object.entries(fields).forEach(([key, value]) => {
        if (typeof value !== 'undefined' && value !== null) {
            data.append(key, `${value}`);
        }
    });

    return data;
};

export const useHttpClient = () => {
    const token = useAppSelector((state) => state.user.token ?? null);

    const httpClient = useMemo(
        () => ({
            call: <T>(action: string, fields: Fields = {}, tokenRequired = false) => {
                const data = prepareRequestData(token ?? '', action, fields);

                return new Promise<AxiosResponse<T>>((resolve, reject) => {
                    if (tokenRequired && !token) {
                        return;
                    }

                    axios
                        .post<ApiResponse>('', data)
                        .then((response) => {
                            resolve(response as AxiosResponse<T>);
                        })
                        .catch(reject);
                });
            },
        }),
        [token],
    );

    return httpClient;
};