import Typography from "@mui/material/Typography";

export const FooterDisclaimerContentDe = () => {
    return <>
        <Typography>
            Willkommen bei OroPuro, dem führenden Online-Casino, in dem Spannung auf Luxus trifft! Mit einer großen Auswahl an hochwertigen Spielen, von klassischen Slots bis hin zu Live-Dealer-Tischen, bietet OroPuro jedem Spieler ein unvergleichliches Spielerlebnis. Unsere Plattform ist benutzerfreundlich gestaltet, bietet schnelle Auszahlungen und höchste Sicherheit – die perfekte Wahl für Anfänger und erfahrene Spieler gleichermaßen.
        </Typography>
        <Typography variant={'h4'}>
            Warum OroPuro wählen?
        </Typography>
        <ul>
            <li>Große Spielauswahl: <span>Entdecken Sie Hunderte von aufregenden Slots, Tischspielen und Live-Casino-Optionen von führenden Softwareanbietern.</span></li>
            <li>Sicher und geschützt: <span>Spielen Sie mit Vertrauen, da Ihre persönlichen und finanziellen Informationen durch modernste Verschlüsselungstechnologien geschützt sind.</span></li>
            <li>Schnelle Auszahlungen: <span>Profitieren Sie von schnellen Auszahlungen über mehrere vertrauenswürdige Zahlungsmethoden.</span></li>
            <li>Exklusive Boni: <span>Nutzen Sie großzügige Willkommensboni, Aktionen und Treueprogramme.</span></li>
            <li>Mobilfreundlich: <span>Greifen Sie jederzeit und überall auf OroPuro zu, sei es auf Ihrem Desktop, Tablet oder Smartphone.</span></li>
        </ul>
        <Typography>
            Ob beim Drehen der Walzen, Pokerspielen oder bei Live-Roulette – OroPuro bietet erstklassige Unterhaltung in einer sicheren und benutzerfreundlichen Umgebung. Treten Sie noch heute bei und erleben Sie die Welt von OroPuro Online Casino!
        </Typography>
    </>
}
