import Typography from "@mui/material/Typography";

export const FooterDisclaimerContentPt = () => {
    return <>
        <Typography>
            Bem-vindo ao OroPuro, o principal cassino online onde emoção encontra luxo! Oferecendo uma vasta seleção de jogos de alta qualidade, desde slots clássicos até mesas com crupiês ao vivo, o OroPuro garante que cada jogador desfrute de uma experiência de jogo incomparável. Nossa plataforma foi projetada para oferecer navegação intuitiva, pagamentos rápidos e segurança de alto nível, sendo a escolha perfeita tanto para iniciantes quanto para jogadores experientes.
        </Typography>
        <Typography variant={'h4'}>
            Por que escolher OroPuro?
        </Typography>
        <ul>
            <li>Ampla variedade de jogos: <span>Explore centenas de slots emocionantes, jogos de mesa e opções de cassino ao vivo dos principais fornecedores de software.</span></li>
            <li>Seguro e protegido: <span>Jogue com confiança, sabendo que suas informações pessoais e financeiras estão protegidas com as mais recentes tecnologias de criptografia.</span></li>
            <li>Pagamentos rápidos: <span>Desfrute de retiradas rápidas com múltiplos métodos de pagamento confiáveis.</span></li>
            <li>Bônus exclusivos: <span>Aproveite generosos bônus de boas-vindas, promoções e recompensas de fidelidade.</span></li>
            <li>Compatível com dispositivos móveis: <span>Acesse o OroPuro a qualquer hora e em qualquer lugar, seja no seu computador, tablet ou smartphone.</span></li>
        </ul>
        <Typography>
            Quer você esteja aqui para girar os rolos, tentar a sorte no pôquer ou sentir a emoção da roleta ao vivo, o OroPuro oferece entretenimento de alta qualidade em um ambiente seguro e intuitivo. Junte-se a nós hoje e comece a ganhar muito no OroPuro Casino Online!
        </Typography>
    </>
}
