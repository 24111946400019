import { createTheme } from "@mui/material/styles";
import { alpha, getContrastRatio } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        surface: Palette['primary'];
    }

    interface PaletteOptions {
        surface?: PaletteOptions['primary'];
    }
}

export const theme = createTheme({
    spacing: 10,
    typography: {
        fontFamily: 'Jost, sans-serif'
    },
    palette: {
        text: {
            primary: '#E4DCD5'
        },
        background: {
            default: '#0A0D11',
            paper: '#1d2024',
        },
        mode: 'dark',
        primary: {
            light: '#F7E8D3',
            main: '#FFD459',
            dark: '#D5A367',
            contrastText: '#18171F'
        },
        surface: {
            main: '#22242A',
            light: alpha('#22242A', 0.5),
            dark: alpha('#22242A', 0.9),
            contrastText: getContrastRatio('#fff', '#22242A') > 4.5 ? '#111' : '#fff',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (theme) => ({
                ":root": {
                    '--dark-bg-color': theme.palette.background.default
                },
                a: {
                    color: 'inherit',
                    textDecoration: 'none',
                },
                '& html, body, #root': {
                    height: '100dvh',
                    overflow:' hidden',
                    backgroundColor: theme.palette.background.default,
                }
            }),
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    border: `1px solid ${alpha('#fff', 0.07)}`,
                    backgroundImage: 'none'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    borderWidth: '2px',
                    '&:hover': {
                        borderWidth: '2px',
                    }
                },
                outlined: ({ theme }) => ({
                    color: 'white',
                    borderColor: theme.palette.primary.main,
                    '&:hover': {
                        borderColor: theme.palette.primary.dark,
                    },
                }),
                contained: ({ ownerState }: any) => ({
                    ...(ownerState.color === 'primary' && {
                        background: 'linear-gradient(to right, #FFF59B, #FBBA72)',
                        color: '#000',
                    }),
                }),
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '&:hover fieldset': {
                            borderColor: theme.palette.primary.dark,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.main,
                        },
                    },
                })
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.spacing(1),
                    "&:last-of-type": {
                        borderBottomLeftRadius: theme.spacing(1),
                        borderBottomRightRadius: theme.spacing(1),
                    },
                    "&:first-of-type": {
                        borderTopLeftRadius: theme.spacing(1),
                        borderTopRightRadius: theme.spacing(1),
                    },
                    "&.Mui-expanded": { margin: 0 },
                }),
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: ({ theme }) => ({
                    minHeight: "36px",
                    borderRadius: theme.spacing(1),
                    px: 1.5,
                }),
                content: ({ theme }) => ({
                    my: theme.spacing(0.3),
                    display: "flex",
                    alignItems: "center",
                    "&.Mui-expanded": { minHeight: "36px" },
                }),
            },
        },
        MuiTable: {
            styleOverrides: {
                root: ({ theme }: any) => ({
                    // borderRadius: theme.shape.borderRadius,
                    borderCollapse: 'separate',
                    borderSpacing: '0 5px'
                }),
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: ({ theme }: any)=> ({
                    padding: theme.spacing(1, 2),
                    fontWeight: 600,
                    borderBottom: 'none'
                }),
                head: ({ theme }: any) => ({
                    fontSize: '14px',
                    color: alpha(theme.palette.text.primary, 0.4),
                }),
                body: ({ theme }: any) => ({
                    fontSize: '16px',
                    backgroundColor: '#101317',
                    color: '#E4DCD5',
                    '&:first-child': {
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                    },
                    '&:last-child': {
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                    },
                }),
            },
        },
    },
});