import Typography from "@mui/material/Typography";

export const FooterDisclaimerContentEs = () => {
    return <>
        <Typography>
            ¡Bienvenido a OroPuro, el principal casino en línea donde la emoción se encuentra con el lujo! Con una amplia selección de juegos de alta calidad, desde tragamonedas clásicas hasta mesas con crupier en vivo, OroPuro garantiza que cada jugador disfrute de una experiencia de juego incomparable. Nuestra plataforma está diseñada para ofrecer navegación fluida, pagos rápidos y seguridad de primer nivel, siendo la opción perfecta tanto para jugadores principiantes como experimentados.
        </Typography>
        <Typography variant={'h4'}>
            ¿Por qué elegir OroPuro?
        </Typography>
        <ul>
            <li>Amplia variedad de juegos: <span>Explore cientos de tragamonedas emocionantes, juegos de mesa y opciones de casino en vivo de los principales proveedores de software.</span></li>
            <li>Seguro y protegido: <span>Juegue con confianza sabiendo que su información personal y financiera está protegida con las últimas tecnologías de encriptación.</span></li>
            <li>Pagos rápidos: <span>Disfrute de retiros rápidos con múltiples métodos de pago confiables.</span></li>
            <li>Bonos exclusivos: <span>Aproveche generosos bonos de bienvenida, promociones y recompensas de lealtad.</span></li>
            <li>Compatible con dispositivos móviles: <span>Acceda a OroPuro en cualquier momento y lugar, desde su computadora, tableta o teléfono inteligente.</span></li>
        </ul>
        <Typography>
            Ya sea que esté aquí para girar los carretes, jugar al póker o disfrutar de la emoción de la ruleta en vivo, OroPuro ofrece entretenimiento de primer nivel en un entorno seguro y fácil de usar. ¡Únase hoy y comience a ganar en grande en OroPuro Casino en línea!
        </Typography>
    </>
}
