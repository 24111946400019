import { FC } from "react";
import { TopGameHolder } from "./models/models";
import { getCurrentDomain } from "../../../config";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {GameCardView} from "../games/GameCardView";

interface TopGameCardProps {
    game: TopGameHolder
    onClick?: (game: TopGameHolder) => void
    mobileMode?: boolean
}

export const TopGameCard: FC<TopGameCardProps> = ({game, onClick, mobileMode = false}) => {
    const { xxSmallScreen: xs, smallScreen: sm, largeScreen: lg } = useCommonMediaQueries()
    const cardWidth = xs ? '85px' : sm ? '150px' : lg ? '200px' : '9vw'

    return <GameCardView
        mobileMode={mobileMode}
        width={cardWidth}
        picUrl={`${getCurrentDomain()}${game.gamePic}`}
        onClick={onClick ? () => onClick(game) : undefined}
        gameLink={`/launch_game?internalId=${game.gameID}`}
    />
}