import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const deLang: LangPack = {
    lang: Lang.DE,
    langName: "German",
    langShort: "de",
    pack: {
        common: {
            noBTN: "Nein",
            yesBTN: "Ja",
            errorCodeTitle: "Fehlercode",
            pageSizeSwitcherLabel: "Reihen auf Seite",
            ageConfirmationMessage: "Bestätigen Sie, dass Sie 18 Jahre alt sind?",
            iConfirmBTN: "Ich bestätige",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "Möchten Sie sich wirklich abmelden?",
            logOut: "Abmelden",
            depositMoney: "Geld einzahlen",
            gamesHistory: "Spielehistorie",
            transactions: "Transaktionen",
            paymentAccounts: "Zahlungskonten",
            userInfo: "Benutzerinformationen",
            withDrawMoney: "Geld abheben"
        },
        mobileMenu: {
            profile: 'Profil',
            topUpBalance: 'Guthaben auffüllen',
            logout: 'Abmelden'
        },
        dashboard: {
            title: 'Dashboard',
            youMustBeLoggedMessage: 'Sie müssen angemeldet sein, um diesen Abschnitt anzuzeigen!'
        },
        internalErrorMessages: {
            networkError: "Ein Netzwerkfehler ist aufgetreten, während die Anfrage ausgeführt wurde.",
            error502: "Ein Serverfehler 502 ist bei der Ausführung der Anfrage aufgetreten."
        },
        errorMessages: {
            2: "Keine Einträge",
            3: "E-Mail-Adresse des Benutzers bereits vorhanden",
            4: "Telefonnummer des Benutzers bereits vorhanden",
            5: "Benutzer bereits angemeldet",
            6: "Falsches Passwort",
            7: "Leeres Spielergebnis",
            8: "Die ID des Ergebnisses stimmt nicht mit der Anforderungs-ID überein",
            9: "Fehler bei der Ergebnisanforderung",
            10: "Keine Sitzungs-ID in der Antwort",
            11: "Benutzer bereits vorhanden",
            12: "Ihr Token ist ungültig. Melden Sie sich erneut an.",
            13: "Sie haben nicht genügend Rechte, um diese Aktion auszuführen",
            14: "Benutzer-ID für diese Aktion fehlt",
            15: "Fehlender Geldbetrag",
            16: "Aktion existiert nicht",
            17: "Fehlendes Token",
            18: "Nicht genügend Geld",
            19: "Sie müssen der Elternteil dieses Benutzers sein, um diese Aktion auszuführen",
            20: "Benutzer existiert nicht",
            21: "Der Werttyp für dieses Feld ist falsch",
            22: "Passwort fehlt",
            23: "Benutzername fehlt",
            24: "Spiel-ID fehlt",
            25: "Neues Passwort fehlt",
            26: "Token konnte nicht eingerichtet werden",
            27: "Token konnte nicht gelöscht werden",
            28: "Benutzer-ID fehlt",
            29: "Falsches Datums- und Uhrzeitformat",
            30: "Konnte Benutzer-Sperrung nicht aktualisieren",
            31: "Dieser Benutzer muss in Ihrem Baum sein, um diese Aktion auszuführen",
            32: "Dieser Benutzer ist gesperrt und kann keine Aktionen durchführen",
            33: "Währung fehlt",
            34: "Maximaler Wert fehlt",
            35: "Limit-Typ muss einmalig, täglich, wöchentlich oder monatlich sein",
            36: "Limit-Typ fehlt",
            37: "Anbieter existiert nicht",
            38: "Bedingungsbetrag für Stufe fehlt",
            39: "Bedingungstyp für Stufe fehlt",
            40: "Stufentitel fehlt",
            41: "Cashback für diese Stufe existiert bereits",
            42: "Cashback für diese Stufe existiert nicht",
            43: "Bonus für diesen Promocode existiert bereits",
            44: "Bonus existiert nicht",
            45: "Promocode fehlt",
            46: "Startzeit fehlt",
            47: "Bedingungsbetrag für Einzahlung fehlt",
            48: "Wette fehlt",
            49: "Gültigkeitsdauer fehlt",
            50: "ID fehlt",
            51: "Cashback-Typ fehlt, muss 'Wette' oder 'Einzahlung' sein",
            52: "Cashback-Rate fehlt",
            53: "Diese Währung ist nicht verfügbar",
            54: "Ban-Parameter fehlt - true oder false",
            55: "Die Bankengruppe für diese Währung ist ungültig",
            56: "Name der Zahlungsmethode fehlt",
            57: "Obligatorische Parameter für diese Zahlungsmethode fehlen",
            58: "Diese Zahlungsmethode existiert nicht",
            59: "Diese Zahlungsmethode unterstützt die Währung dieses Benutzers nicht",
            60: "Dieser Benutzer hat kein vorhandenes Konto für dieses Zahlungssystem",
            61: "Der Elternteil dieses Benutzers hat kein vorhandenes Konto für dieses Zahlungssystem",
            62: "Transfer-ID fehlt",
            63: "Transfer mit dieser ID existiert nicht",
            64: "Dieser Transfer wird von einem anderen Elternteil angefordert",
            65: "Sie haben immer noch einen Bonus, der nicht abgelaufen ist"
        },
        loginForm: {
            usernameFieldLabel: "Spitzname",
            passwordFieldLabel: "Passwort",
            loginBTN: "Anmelden"
        },
        userInfoForm: {
            emailIsRequiredMess: 'E-Mail ist erforderlich',
            notValidEmail: 'Geben Sie eine gültige E-Mail-Adresse ein',
            currentPassFieldLabel: "Aktuelles Passwort",
            emailFieldLabel: "E-Mail",
            emptyFieldWarningMessage: "Dieses Feld darf nicht leer sein",
            firstnameFieldLabel: "Vorname",
            lastnameFieldLabel: "Nachname",
            newPassFieldLabel: "Neues Passwort",
            passNotMatchWarningMessage: "Neues Passwort und wiederholtes Passwort stimmen nicht überein",
            phoneFieldLabel: "Telefon",
            retypePassFieldLabel: "Neues Passwort erneut eingeben",
            saveInfoBTN: "Info speichern",
            updatePassBTN: "Passwort aktualisieren",
            usernameLabel: "Spitzname"
        },
        transactionsTable: {
            amountTableHeader: "Betrag",
            methodTableHeader: "Methode",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Einzahlung/Auszahlung",
            dateTimeTableHeader: "Datum/Uhrzeit"
        },
        gamesHistory: {
            providerTableHeader: 'Anbieter',
            gameTableHeader: 'Spiel',
            betAmountTableHeader: 'Einsatzbetrag',
            winAmountTableHeader: 'Gewinnbetrag',
            dateTimeTableHeader: 'Datum/Uhrzeit'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Es stehen keine weiteren Zahlungsmethoden zur Verfügung",
            paymentMethodsDropdownLabel: "Zahlungsmethoden",
            addBTN: "Hinzufügen",
            yourPaymentAccountsTitle: "Ihre Zahlungskonten",
            addPaymentMethodBTN: "Zahlungsmethode hinzufügen"
        },
        moneyInput: {
            maxAmountMessage: "Höchstwert",
            exceedAmountError: "Höchstwert überschritten.",
            moreThanOneDotError: "Sie haben einen zusätzlichen Punkt gemacht.",
            moreThanTwoDecimals: "Sie haben mehr als zwei Dezimalstellen eingegeben."
        },
        withdrawMoney: {
            requestPayoutLabel: "Von Benutzer abheben",
            moneySuccessfulRequestedMessage: "Sie haben %money% für die Auszahlung angefordert.",
            requestBtn: "Geld anfordern"
        },
        argentina: {
            common: {
                linkToAllTitle: "Alle",
                topGames: 'Top-Spiele',
                providers: 'Anbieter',
                seeAllBTN: 'Alle',
                returnBackBTN: 'Zurück'
            },
            dashboard: {
                sideMenu: {
                    userInfo: 'Benutzerinfo',
                    transactions: 'Transaktionen',
                    gamesHistory: 'Spielverlauf',
                    logoutBTN: 'Abmelden'
                },
                userInfo: {
                    userInfoSection: 'Benutzerinfo',
                    changePasswordSection: 'Passwort ändern'
                }
            },
            carousel: {
                slide1: {
                    header: 'Deine Reise zum Gewinnen<br/>Beginnt bei Imagine Live',
                    tagline: 'Echte Dealer, echte Gewinne.'
                },
                slide2: {
                    header: 'Erlebe den<br/>Reiz der virtuellen Sportarten',
                    tagline: 'Spielen. Gewinnen. Wiederholen.'
                },
                slide3: {
                    header: 'Demnächst im <br/>Live-Casino',
                    tagline: 'Ihre Lieblingsanbieter'
                },
                slide4: {
                    header: 'Magst du Süßigkeiten?',
                    tagline: 'Dann warte auf Galaxsys!'
                },
                slide5: {
                    header: 'Ezugi wartet auf Sie! <br/>Spielen Sie direkt auf OroPuro',
                    tagline: 'Spielen und gewinnen!'
                },
            },
            menuLinkTitles: {
                home: "Startseite",
                casino: "Casino",
                liveCasino: "Live-Casino",
                virtualGames: "Virtuelle Spiele",
            },
            sideBarSections: {
                games: "Spiele",
                topCrashGames: "Top Crash-Spiele",
                other: "Andere",
                sportsRules: "Sportregeln"
            },
            headerMenu: {
                home: 'Startseite',
                casino: 'Casino',
                slots: 'Slots',
                shooters: 'Shooter',
                tableGames: 'Tischspiele',
                liveCasino: 'Live-Casino',
                crash: 'Crash',
                sport: 'Sport',
                sportPrelive: 'Pre-Live',
                sportSubCatTitle: 'Sportarten',
                gamesSubCatTitle: 'Spiele',
                menu: 'Menü'
            },
            categoriesDescription: {
                casino: 'Drehen Sie, um große Jackpots zu gewinnen!',
                slots: 'Drehen Sie, um große Jackpots zu gewinnen!',
                shooters: 'Zielen, schießen und punkten!',
                tableGames: 'Klassische Spiele, endloser Spaß!',
                liveCasino: 'Spiele mit Live-Dealern!',
                crash: 'Setzen, zuschauen und schnell auszahlen!',
                sport: 'Platzieren Sie Wetten auf Sport mit uns!'
            },
            footer: {
                backToTopBTN: 'Nach oben',
                copyright: 'Alle Rechte vorbehalten © OroPuro 2024<br/>18+ Wetten Sie verantwortungsvoll',
                disclaimerTitle: 'OroPuro Online-Casino: Ihr ultimatives Ziel für Premium-Gaming'

            },
            topWins: {
                game: 'Spiel',
                date: 'Datum',
                user: 'Benutzer',
                betAmount: 'Wetteinsatz',
                multiplier: 'Multiplikator',
                payout: 'Auszahlung'
            },
            cashback: {
                title: 'Cashback gehört Ihnen!',
                gotItBTN: 'Verstanden',
            }
        },
        gameSearch: {
            placeholder: 'Geben Sie hier den Spielnamen ein'
        }
    }
}
