import { FC } from "react";
import { Box } from "@mui/material";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import logo from '../../../../assets/oropuroV2/Logo.png'
import {Link} from "react-router-dom";

interface LogoProps {
    height?: string
}

export const Logo: FC<LogoProps> = ({height}) => {
    const {xxSmallScreen: xs} = useCommonMediaQueries()

    return <Box
        component={Link}
        to={'/'}
        height={height ?? (xs ? '35px' : '50px')}
        sx={{
            aspectRatio: '3.63/1',
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
        }}
    />
}