import {FC} from "react";
import {Box, Stack, useTheme} from "@mui/material";
import {CategoryCardProps} from "./CategoryCard.interface";
import {Link} from "react-router-dom";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Typography from "@mui/material/Typography";

export const CategoryCard: FC<CategoryCardProps> = ({link, bgImageDesktop, bgImageMobile, frontImage, title, description}) => {
    const {xxSmallScreen: xs, smallScreen: sm, mediumScreen: md} = useCommonMediaQueries()
    const theme = useTheme()
    const padding = theme.spacing(sm ? 0 : md ? 0.5 : 1.5)
    const topOffset = '1vw'

    return <Stack width={'100%'} height={'100%'} p={padding}  component={Link} to={link}>
        <Box position={'relative'}>
            {/* Front image box */}
            <Box
                bottom={0}
                width={'100%'}
                position={'absolute'}
                sx={{
                    aspectRatio: sm ? '1/1' : '1.9/1.08',
                    backgroundImage: `url(${frontImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: '20% 0%'
                }}
            />
            {/* Background image box */}
            <Box
                mt={topOffset}
                borderRadius={sm ? 0 : theme.spacing(md ? 1 : 2)}
                sx={{
                    aspectRatio: sm ? '1/1' : '1.9/1',
                    backgroundImage: `url(${sm ? bgImageMobile : bgImageDesktop})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderTopLeftRadius: sm ? theme.spacing(2) : undefined,
                    borderTopRightRadius: sm ? theme.spacing(2) : undefined,
                }}
            />
        </Box>

        <Box
            flexDirection={sm ? 'column' : 'row'}
            gap={sm ? 1 : 0}
            flexGrow={1}
            display={'flex'}
            alignItems={sm ? 'center' : 'end'}
            p={1}
            sx={{
                border: sm ? '1px solid rgba(255, 255, 255, 0.1)' : undefined,
                borderTop: 'none',
                color: '#E1BF7D',
                borderBottomLeftRadius: sm ? theme.spacing(2) : undefined,
                borderBottomRightRadius: sm ? theme.spacing(2) : undefined,
        }}
        >
            <Stack
                flex={1}
                gap={1}
            >
                <Typography
                    fontSize={xs ? '12px' : sm ? '18px' : '35px'}
                    textAlign={sm ? 'center' : undefined}
                    textTransform={sm ? 'uppercase' : 'capitalize'}
                    sx={{
                        // color: sm ? '#E4DCD5' : 'inherit'
                    }}
                >
                    {title}
                </Typography>
                <Typography fontSize={sm ? '10px' : '18px'} sx={{color: '#E4DCD5'}}>
                    {description}
                </Typography>
            </Stack>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'50%'}
                width={'44px'}
                border={'1px solid #E1BF7D'}
                sx={{
                    aspectRatio: '1/1',
                }}
            >
                <ArrowForwardIcon sx={{fontSize: '30px'}}/>
            </Box>
        </Box>
    </Stack>
}