import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const CasinoIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M19,2.5c-0.4-0.1-1-0.3-1.4-0.5c-0.5-0.1-0.9-0.2-1.7-0.2c-0.6,0-1.1,0-1.4,0.1
	c-0.2,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.6,0.2-0.9,0.4c-0.3,0.2-0.8,0.4-1,0.6S11.2,3.7,11,3.9c-0.2,0.2-0.6,0.6-0.8,0.8
	C10,4.9,9.6,5.4,9.3,5.8C9,6.2,8.5,6.9,8.2,7.4L7.6,8.3L7.1,8C6.8,7.8,6.4,7.6,6.2,7.5S5.6,7.3,5.3,7.3c-0.4-0.1-0.6-0.1-1,0
	C4.1,7.4,3.7,7.5,3.5,7.5C3.3,7.6,3,7.8,2.7,7.9C2.5,8.1,2.1,8.4,1.8,8.7C1.6,8.9,1.3,9.3,1.1,9.6c-0.2,0.3-0.4,0.7-0.6,1
	c-0.1,0.3-0.3,0.8-0.4,1.1C0,12.2,0,12.5,0,13.1c0,0.5,0,1,0.1,1.2c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.6,0.4,0.8
	C0.8,16,1,16.4,1.2,16.5c0.2,0.2,0.5,0.5,0.8,0.7c0.2,0.2,0.7,0.5,1.1,0.6c0.3,0.2,0.8,0.4,1,0.4c0.2,0.1,0.6,0.1,0.8,0.1
	c0.4,0,0.4,0,0.4-0.1c0-0.1-0.1-0.5-0.2-0.8C5,17,5,16.6,5.1,15.9c0-0.6,0.1-1.2,0.1-1.5c0.1-0.3,0.2-0.7,0.2-0.9
	c0.1-0.2,0.3-0.7,0.4-1c0.1-0.3,0.4-0.8,0.6-1.1c0.2-0.3,0.6-0.8,0.9-1c0.3-0.3,0.8-0.7,1.1-0.9C8.7,9.2,9,9,9.1,9
	c0.1,0,0.2-0.1,0.2-0.1c0,0-0.2-0.1-0.4-0.2S8.4,8.5,8.3,8.5s-0.2,0-0.2-0.1c0,0,0.1-0.2,0.3-0.5c0.2-0.3,0.5-0.7,0.8-1
	c0.2-0.3,0.6-0.7,0.8-0.8c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.1,0.5-0.3,0.7-0.5c0.2-0.1,0.5-0.2,0.5-0.2s0.4,0.2,0.7,0.5
	c0.3,0.3,0.7,0.7,0.9,1c0.2,0.2,0.5,0.7,0.7,1c0.2,0.3,0.4,0.8,0.5,1.1c0.1,0.3,0.2,0.8,0.2,1.2c0.1,0.5,0.1,0.8,0,1.2
	c0,0.4-0.1,0.7-0.1,0.7c0,0-0.2-0.1-0.4-0.2c-0.2-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.6-0.1-1-0.1
	c-0.3,0-0.7,0-0.9,0.1c-0.2,0.1-0.6,0.2-0.8,0.4c-0.2,0.1-0.6,0.4-0.7,0.5c-0.2,0.1-0.5,0.5-0.7,0.8c-0.2,0.3-0.5,0.8-0.7,1.2
	c-0.2,0.3-0.4,0.9-0.4,1.2c-0.1,0.3-0.2,0.9-0.2,1.2c0,0.3,0,0.9,0,1.2c0,0.3,0.1,0.8,0.2,1.1c0.1,0.3,0.3,0.7,0.4,1
	c0.2,0.4,0.4,0.7,0.8,1.1c0.4,0.4,0.8,0.7,1.1,0.9c0.3,0.2,0.9,0.4,1.3,0.6c0.6,0.2,0.8,0.2,1.6,0.3c0.8,0,1.1,0,1.5-0.1
	c0.3-0.1,0.7-0.2,1-0.3c0.2-0.1,0.6-0.3,0.9-0.5c0.2-0.2,0.6-0.5,0.9-0.8c0.3-0.3,0.6-0.7,0.8-0.9c0.1-0.2,0.4-0.6,0.5-0.9
	c0.1-0.3,0.3-0.7,0.4-1c0.1-0.3,0.2-0.7,0.2-1c0-0.3,0-0.7,0-1c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.2-0.5-0.3-0.7
	c-0.1-0.2-0.4-0.5-0.5-0.7c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.5-0.3-0.8-0.4c-0.3-0.1-0.7-0.3-0.9-0.3c-0.2-0.1-0.4-0.1-0.5-0.1
	c0,0,0-0.1,0.1-0.3c0.1-0.2,0.1-0.8,0.1-1.6c0-1.2,0-1.4-0.2-1.9c-0.1-0.3-0.2-0.8-0.3-1c-0.1-0.2-0.2-0.4-0.2-0.4
	c0,0,0.3,0.1,0.7,0.3c0.4,0.2,0.9,0.4,1.2,0.5c0.3,0.1,0.8,0.2,1,0.3c0.3,0.1,0.8,0.1,1.4,0.1c0.6,0,1.1,0,1.4-0.1
	c0.3-0.1,0.7-0.2,0.9-0.2c0.2-0.1,0.6-0.2,0.8-0.4C22.4,7,22.8,6.8,23,6.7c0.2-0.1,0.5-0.4,0.7-0.6C23.9,5.9,24,5.7,24,5.7
	S23.6,5.4,23.2,5c-0.4-0.3-1.1-0.8-1.5-1c-0.4-0.2-1-0.6-1.4-0.8C19.9,3,19.3,2.7,19,2.5z"/>
            </svg>
        </SvgIcon>
    );
}

export default CasinoIcon;