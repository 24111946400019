import {FC} from "react";
import {Box} from "@mui/material";
import {CategoryCard} from "./CategoryCard";
import {CategoryCardProps} from "./CategoryCard.interface";
import CasinoBG from '../../../../assets/oropuro_redesigned_v2/categories/casino_cover_bg.webp'
import CasinoBGMob from '../../../../assets/oropuro_redesigned_v2/categories/casino_cover_bg_mobile.webp'
import CasinoFront from '../../../../assets/oropuro_redesigned_v2/categories/casino_cover_front.webp'
import LiveCasinoBG from '../../../../assets/oropuro_redesigned_v2/categories/live_casino_cover_bg.webp'
import LiveCasinoBGMob from '../../../../assets/oropuro_redesigned_v2/categories/live_casino_cover_bg_mobile.webp'
import LiveCasinoFront from '../../../../assets/oropuro_redesigned_v2/categories/live_casino_cover_front.webp'
import SportBG from '../../../../assets/oropuro_redesigned_v2/categories/sport_cover_bg.webp'
import SportBGMob from '../../../../assets/oropuro_redesigned_v2/categories/sport_cover_bg_mobile.webp'
import SportFront from '../../../../assets/oropuro_redesigned_v2/categories/sport_cover_front.webp'
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const CategoriesSection: FC = () => {
    const {languagePack: {pack: {argentina: {headerMenu: lang, categoriesDescription: langDesc}}}} = useLanguage()
    const {mediumScreen: md} = useCommonMediaQueries()

    const cards: CategoryCardProps[] = [
        {
            bgImageMobile: CasinoBGMob,
            bgImageDesktop: CasinoBG,
            frontImage: CasinoFront,
            link: '/casino',
            title: lang.casino,
            description: langDesc.casino
        },
        {
            bgImageMobile: LiveCasinoBGMob,
            bgImageDesktop: LiveCasinoBG,
            frontImage: LiveCasinoFront,
            link: '/live_casino',
            title: lang.liveCasino,
            description: langDesc.liveCasino
        },
        {
            bgImageMobile: SportBGMob,
            bgImageDesktop: SportBG,
            frontImage: SportFront,
            link: '/sport',
            title: lang.sport,
            description: langDesc.sport
        }
    ]

    return <Box
        display={'flex'}
        gap={md ? 1 : 2}
    >
        {cards.map((card, indx) => <Box
            key={`${card.link}-${indx}`}
            flex={1}
            // sx={{backgroundColor: '#da01fc'}}
        >
            <CategoryCard {...card}/>
        </Box>)}
    </Box>
}