import React, { FC } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, alpha, Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import {Link} from "react-router-dom";
import {SideMenuSectionProps} from "./SideMenuSection.interface";

export const SideMenuSection: FC<SideMenuSectionProps> = ({sectionIcon: SectionIcon, sectionTitle, menuItems, onExpandChanged, expanded}) => {
    return (
        <Accordion
            expanded={expanded}
            onChange={onExpandChanged}
            disableGutters
            elevation={0}
        >
            <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: "#FFFFFF" }} />}
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={1}
                >
                    <SectionIcon sx={{color: '#FDD887'}}/>
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            color: "#FFFFFF",
                        }}
                    >
                        {sectionTitle}
                    </Typography>
                </Box>
            </AccordionSummary>

            {/* Accordion Content */}
            <AccordionDetails sx={{ p: 0 }}>
                <List component="nav" disablePadding>
                    {menuItems.map(({text, path, icon: Icon}, index) => (
                        <ListItemButton
                            component={Link} // Используем Link из react-router-dom
                            to={path} // Добавляем путь для ссылки
                            key={`${index}-${path}`}
                            sx={{
                                py: 0.8,
                                pl: 1.5,
                                pr: 0,
                                "&:hover": { bgcolor: "#383838", transition: "background-color 0.3s ease" },
                                gap: 1
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: alpha("#E4DCD5", 0.6),
                                    minWidth: "24px", // Поджимаем иконку
                                }}
                            >
                                <Icon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={text}
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: "0.9rem",
                                        color: alpha("#E4DCD5", 0.6),
                                    },
                                }}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

// const menuItems = [
//     { text: "Casino", icon: <Casino />, path: "/casino" },
//     { text: "Live Casino", icon: <LiveTv />, path: "/live-casino" },
//     { text: "Fast Games", icon: <Rocket />, path: "/fast-games" },
//     { text: "Table Games", icon: <TableChart />, path: "/table-games" },
//     { text: "Shooters", icon: <SportsEsports />, path: "/shooters" },
// ];
