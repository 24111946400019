import {FC} from "react";
import {alpha, Box, Button, Paper, Stack, useTheme} from "@mui/material";
import {FooterProps} from "./Footer.interface";
import {useFooterLogic} from "./useFooterLogic";
import {Logo} from "../logo/Logo";
import Typography from "@mui/material/Typography";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Link } from "react-router-dom";
import {useLanguage} from "../../../features/localisation/useLanguage";
import LanguageSelector from "../language_selector/LanguageSelector";

export const Footer: FC<FooterProps> = ({scrollContainerRef}) => {
    const theme = useTheme()
    const { languagePack: {pack: {argentina: {headerMenu: menuLang, footer: footerLang}}}} = useLanguage()
    const scrollToTop = useFooterLogic(scrollContainerRef)
    const { xxSmallScreen: xs, smallScreen: sm, largeScreen: lg} = useCommonMediaQueries()

    const colGap = xs ? theme.spacing(1) : theme.spacing(2)
    const colWidth = sm ? `calc((100% - ${colGap}) / 2)` : `calc((100% - ${colGap} * 3) / 4)`
    const subtleColor = alpha('#E4DCD5', 0.6)

    const columns: {title: string, items: {name: string, link: string}[]}[] = [
        {
            title: 'Oropuro',
            items: [
                {name: menuLang.home, link: '/'},
                {name: menuLang.casino, link: '/casino'},
                {name: menuLang.liveCasino, link: '/live_casino'},
                {name: menuLang.slots, link: '/slots'},
                {name: menuLang.tableGames, link: '/table_games'},
                {name: menuLang.crash, link: '/crash'},
                {name: menuLang.gamesSubCatTitle, link: '/games'},
            ]
        },
        {
            title: menuLang.sportSubCatTitle,
            items: [
                {name: menuLang.sport, link: '/sport'},
                {name: menuLang.sportPrelive, link: '/sport_prelive'},
            ]
        },
    ]


    return <Paper
        sx={theme => ({
            backgroundColor: '#101317',
            borderColor: alpha(theme.palette.text.primary, 0.1),
            p: sm ? 2 :5,
            display: 'flex',
            flexDirection: lg ? 'column' : 'row',
            rowGap: 2,
            justifyContent: 'space-between'
        })}
    >
        <Stack
            gap={2}
            flex={2}
            alignItems={'start'}
        >
            <Logo height={'77px'}/>
            <Typography variant={'subtitle1'} fontSize={theme.typography.pxToRem(16)} sx={{color: subtleColor}}>
                {/* TODO localize this*/}
                All rights reserved © Oropuro 2024-2025
            </Typography>
        </Stack>

        <Box
            display={'flex'}
            justifyContent={lg ? 'start' : 'end'}
            flex={4}
            gap={colGap}
            sx={{
                flexWrap: 'wrap',
                '& > *': {
                    flex: 1,
                    maxWidth: colWidth,
                    minWidth: colWidth
                }
            }}
        >
            {columns.map(({title, items}) => <Stack gap={1}>
                <Typography variant={'h3'} fontSize={theme.typography.pxToRem(12)} sx={{color: subtleColor}}>
                    {title}
                </Typography>
                {items.map(({name, link}) => <Typography
                    component={Link}
                    to={link}
                    sx={{color: '#E4DCD5 !important'}}
                >
                    {name}
                </Typography>)}
            </Stack>)}
            <Stack
                justifyContent={'space-between'}
                minWidth={sm ? '100% !important' : undefined}
                gap={1}
            >
                <Box
                    display={'flex'}
                    gap={1}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <LanguageSelector/>
                    <Button
                        onClick={scrollToTop}
                        variant={'outlined'}
                        sx={{
                            borderColor: alpha('#E4DCD5', 0.15),
                            borderWidth: '1px !important',
                            color: '#E4DCD5',
                        }}
                    >
                        {footerLang.backToTopBTN}
                    </Button>
                </Box>
                <Box
                    display={'flex'}
                    justifyContent={'end'}
                    alignItems={'center'}
                    gap={1}
                >
                    <Typography sx={{color: '#E4DCD5 !important'}}>
                        Bet responsibly
                    </Typography>
                    <Box
                        borderRadius={'10px'}
                        border={`1px solid ${alpha('#E4DCD5', 0.1)}`}
                        p={theme.spacing(0.5, 1)}
                        sx={{
                            backgroundColor: '#1D2024'
                        }}
                    >
                        18+
                    </Box>
                </Box>
            </Stack>
        </Box>
    </Paper>
}