import {RefObject} from "react";

export const useFooterLogic = (scrollContainerRef: RefObject<HTMLDivElement>) => {
    const scrollToTop = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return scrollToTop
}