import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const TableGamesIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M23.7,10.9l-1.3-3.7c-0.6-1.5-1.9-2.7-3.5-2.9L15,3.8c-1.5-0.2-3,0.4-4,1.6L8.2,8.9
		c-1,1.2-1.2,2.8-0.7,4.3l1.3,3.7c0.6,1.5,1.9,2.6,3.5,2.9l3.9,0.5c1.5,0.2,3-0.4,4-1.6l2.8-3.5C24,13.9,24.3,12.3,23.7,10.9z
		 M9,4.6L5.9,4.2C4.6,4,3.3,4.6,2.5,5.6L0.7,8.2c-0.7,1-0.9,2.3-0.4,3.4l1.4,3.3c0.5,1.1,1.5,1.9,2.7,2.1l2.4,0.3l-1.2-3.4
		c-0.7-1.9-0.3-4,0.9-5.5l2.9-3.6C9.2,4.7,9.1,4.6,9,4.6z M15.2,5.5c0.3-0.4,1.1-0.3,1.7,0.2c0.6,0.5,0.8,1.2,0.5,1.6
		c-0.3,0.4-1.1,0.3-1.7-0.2C15.1,6.6,14.9,5.9,15.2,5.5z M19.9,9.3c0.3-0.4,1.1-0.3,1.7,0.1s0.8,1.2,0.5,1.6
		c-0.3,0.4-1.1,0.3-1.7-0.2C19.8,10.4,19.6,9.7,19.9,9.3z M11.2,9.3c0.7-0.1,1.3,0.7,1.4,1.7c0.1,1-0.4,2-1.1,2.1s-1.3-0.7-1.4-1.7
		C10,10.3,10.5,9.4,11.2,9.3z M20.5,14.7c0.6-0.1,1.1,0.2,1.1,0.5c0,0.4-0.4,0.7-1,0.8c-0.6,0.1-1.1-0.2-1.1-0.5
		C19.5,15.2,19.9,14.8,20.5,14.7z M17,16c0.6-0.1,1.1,0.2,1.1,0.5c0,0.4-0.4,0.7-1,0.8c-0.6,0.1-1.1-0.2-1.1-0.5
		C16,16.4,16.5,16.1,17,16z M13.2,17.4c0.6-0.1,1.1,0.2,1.1,0.5c0,0.4-0.4,0.7-1,0.8c-0.6,0.1-1.1-0.2-1.1-0.5
		C12.2,17.9,12.7,17.5,13.2,17.4z"/>
            </svg>
        </SvgIcon>
    );
}

export default TableGamesIcon;