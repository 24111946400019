import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const LiveSupport2Icon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M2,12c0-2.2,0.7-4.3,2.1-6.1c1.3-1.8,3.2-3,5.3-3.6c2.1-0.6,4.4-0.4,6.4,0.4
		c2,0.8,3.7,2.3,4.9,4.2c1.1,1.9,1.6,4.1,1.3,6.3c-0.3,2.2-1.2,4.2-2.8,5.8c-1.5,1.6-3.5,2.6-5.7,2.9c-2.2,0.3-4.4-0.1-6.3-1.1
		l-3.9,1.2c-0.2,0-0.4,0.1-0.5,0c-0.2,0-0.3-0.1-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.2,0-0.4,0-0.5l1.2-3.9C2.4,15.3,2,13.7,2,12z
		 M8,11c0,0.3,0.1,0.5,0.3,0.7C8.5,11.9,8.7,12,9,12h6c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
		C15.5,10.1,15.3,10,15,10H9c-0.3,0-0.5,0.1-0.7,0.3C8.1,10.5,8,10.7,8,11z M9,14c-0.3,0-0.5,0.1-0.7,0.3C8.1,14.5,8,14.7,8,15
		c0,0.3,0.1,0.5,0.3,0.7C8.5,15.9,8.7,16,9,16h4c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
		C13.5,14.1,13.3,14,13,14H9z"/>
            </svg>
        </SvgIcon>
    );
}

export default LiveSupport2Icon;