import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from "react";

const CupIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M19.7,4.3l-0.1-0.2c-0.5-2.1-2.3-3.5-4.4-3.5H8.7c-1,0-2,0.3-2.7,1C5.2,2.2,4.6,3.1,4.4,4.1
		L4.3,4.3C3.3,4.5,2.3,5,1.5,5.9C0.5,6.9,0,8.4,0,9.9c0,1.3,0.5,2.6,1.3,3.6C2.1,14.2,3,14.8,4,14.9c1,2,2.7,3.4,4.7,3.6
		c-0.2,1.1-0.7,2.1-1.3,2.9c-0.3,0.4-0.3,0.9-0.1,1.3c0.2,0.4,0.6,0.7,1.1,0.7h7.2c0.4,0,0.9-0.3,1.1-0.7c0.2-0.4,0.2-0.9-0.1-1.3
		c-0.6-0.8-1-1.8-1.3-2.9c1.5-0.2,2.8-0.9,3.8-2.1c0.4-0.5,0.7-1,1-1.5c2.3-0.4,4-2.5,4-5.1C24,7.1,22.1,4.8,19.7,4.3z M17.2,14.9
		c-0.7,0.8-1.6,1.3-2.5,1.3H9.4c-1.5,0-2.9-1.2-3.5-3.1c-0.2-0.5-0.2-1-0.2-1.5v-0.1c0-1.9,0.2-3.8,0.7-5.5l0.1-0.2c0,0,0,0,0,0
		c0,0,0,0,0,0l0.3-1c0.1-0.5,0.4-0.9,0.8-1.3C7.9,3.2,8.3,3,8.7,3h6.5c0.9,0,1.8,0.7,2,1.7l0.3,1c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.2
		c0.5,1.8,0.7,3.7,0.7,5.6v0.1c0,0.5-0.1,1-0.2,1.6C17.9,13.8,17.6,14.4,17.2,14.9z M14.8,8.6l-1.4-0.2l-0.6-1.3
		c-0.3-0.7-1.3-0.7-1.6,0l-0.6,1.3L9.2,8.6c-0.7,0.1-1,1-0.5,1.6l1,1l-0.2,1.4c-0.1,0.8,0.6,1.4,1.3,1L12,13l1.3,0.7
		c0.7,0.4,1.4-0.2,1.3-1l-0.2-1.4l1-1C15.9,9.7,15.6,8.8,14.8,8.6z M21.6,9.9c0,0.8-0.3,1.6-0.9,2.1v-0.5c0-0.7,0-1.3-0.1-1.9
		c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1c-0.1-0.5-0.1-1-0.2-1.5c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2C21.1,7.8,21.6,8.7,21.6,9.9z
		 M3.2,11.5V12c0,0-0.1-0.1-0.1-0.1c-0.5-0.5-0.7-1.2-0.7-2c0-0.9,0.3-1.7,0.9-2.3c0.1-0.1,0.2-0.2,0.4-0.3c0,0,0,0.1,0,0.2
		c0,0.1,0,0.1,0,0.2C3.5,8,3.4,8.5,3.4,9c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.5C3.3,10.2,3.2,10.8,3.2,11.5z M10.5,21
		c0.3-0.8,0.6-1.6,0.7-2.4h1.6c0.1,0.8,0.4,1.6,0.7,2.4H10.5z"/>
            </svg>
        </SvgIcon>
    );
}

export default CupIcon;