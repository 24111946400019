import { FC } from "react";
import { LinearProgress } from "@mui/material";

export const OverlaySpinner: FC = () => {
    return <LinearProgress sx={{
        height: '5px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999
    }}/>
}