import {alpha, Box} from "@mui/material";
import {FC} from "react";
import {GameCardViewProps} from "./GameCardView.interface";
import Typography from "@mui/material/Typography";
import {theme} from "../../../themes/oropuro/theme";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {Link} from "react-router-dom";

export const GameCardView: FC<GameCardViewProps> = (props) => {
    const {width, mobileMode, title, picUrl, onClick, gameLink} = props

    return <Box
        {...(gameLink !== undefined ? {component: Link, to: gameLink} : {})}
        onClick={onClick}
        width={width}
        minWidth={width}
        sx={(theme) => ({
            cursor: 'pointer',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            borderRadius: '20px',
            aspectRatio: '1/1.3',
            overflow: 'hidden',
            border: mobileMode ? `2px solid ${theme.palette.primary.main}` : undefined,
            '&:hover .overlay': {
                opacity: 1,
                visibility: 'visible',
            },
            '&:before': {
                content: `'${title}'`,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: theme.typography.pxToRem(12),
                color: alpha('#fff', 0.6),
            },
        })}
    >
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${picUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        />
        <Box
            borderRadius={'inherit'}
            className="overlay"
            position={'absolute'}
            top={0}
            left={0}
            width={'100%'}
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                backdropFilter: 'blur(8px)',
                backgroundColor: alpha('#000', 0.3),
                opacity: 0,
                visibility: 'hidden',
                transition: 'opacity 0.3s ease, visibility 0.3s ease',
                border: '2px solid #F7CF78',
                pb: '10%'
            }}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                borderRadius={'15px'}
                sx={{
                    p: theme.spacing(1, 1.5),
                    backgroundImage: 'linear-gradient(90.00deg, rgb(211, 160, 97),rgb(247, 232, 211) 51.562%,rgb(189, 133, 69) 100%)',
                    color: 'black',
                    gap: 0.5
                }}
            >
                <PlayArrowIcon/>
                <Typography>
                    Play
                </Typography>
            </Box>

            <Typography sx={{position: 'absolute', bottom: '10%', left: 0, right: 0, textAlign: 'center'}}>
                {title}
            </Typography>
        </Box>
    </Box>
}