import { iUseTopWins } from "./models/hooks";
import { useCallback, useEffect, useRef } from "react";
import { useHttpClient } from "../common_funcs/useHttpClient";
import { useAppDispatch, useAppSelector } from "../../store";
import { AxiosError } from "axios";
import { useLanguage } from "../localisation/useLanguage";
import { setTopWins, setTopWinsLoader } from "./topWinsSlice";
import { TopWinsLoader } from "./models/loaders";
import { setPlayGamesLoader } from "../play_games/playGamesSlice";
import { PlayGamesLoader } from "../play_games/playGamesModels";
import { convertUnixToCompactLocaleDate } from "../common_funcs";
import { v4 as uuidv4 } from "uuid";
import { ShowTopWinsResponse } from "./models/responses";
import { TopWinGame } from "./models/model";
import LoaderService, {ApiRequest} from "../loaderService";

export const useTopWins = (): iUseTopWins => {
    const dispatch = useAppDispatch();
    const httpClient = useHttpClient();
    const { setLocalizedError, handleNetworkErrors } = useLanguage();

    const topWinsState = useAppSelector(state => state.topWins);
    const playGames = useAppSelector(state => state.playGames.games); // Получаем список игр

    const hasTopWinsLoaded = useRef(false);
    const isPicUpdated = useRef(false);

    // 📌 Функция для сопоставления picUrl
    const mapPicUrls = useCallback((topWins: TopWinGame[] | null): TopWinGame[] => {
        if (!topWins) return [];
        return topWins.map(topWin => ({
            ...topWin,
            picUrl: playGames?.find(game => game.Name === topWin.game_name)?.Pic || undefined
        }));
    }, [playGames]);

    // 📌 Загрузка TopWins
    const getTopWins = useCallback(() => {
        if (!LoaderService.isLoading(ApiRequest.ShowTopWins)) {
            LoaderService.setLoading(ApiRequest.ShowTopWins, true)
            dispatch(setTopWinsLoader({
                loader: TopWinsLoader.isTopWinsLoading,
                isLoading: true
            }));

            httpClient
                .call<ShowTopWinsResponse>(ApiRequest.ShowTopWins, {}, true)
                .then(response => {
                    const {success, error, data: topWins} = response.data;
                    if (success && topWins) {
                        const processedTopWins = topWins.map(topWin => ({
                            ...topWin,
                            time: convertUnixToCompactLocaleDate(topWin.time_unix),
                            uuid: uuidv4(),
                            multiplier: parseFloat((topWin.deposit_sum / topWin.withdraw_sum).toFixed(2))
                        }));

                        dispatch(setTopWins(processedTopWins));
                        hasTopWinsLoaded.current = true;
                        isPicUpdated.current = false;
                    }

                    if (error) {
                        if (error.code === 2) {
                            dispatch(setTopWins([]));
                        } else {
                            setLocalizedError(error);
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    handleNetworkErrors(error);
                })
                .finally(() => {
                    LoaderService.finishLoading(ApiRequest.ShowTopWins)
                    dispatch(setPlayGamesLoader({
                        loader: PlayGamesLoader.isProvidersOutcomeLoading,
                        isLoading: false
                    }));
                });
        }
    }, [dispatch, handleNetworkErrors, httpClient, setLocalizedError]);

    useEffect(() => {
        if (hasTopWinsLoaded.current && playGames && !isPicUpdated.current) {
            const updatedTopWins = mapPicUrls(topWinsState.topWins);
            dispatch(setTopWins(updatedTopWins));
            isPicUpdated.current = true;
        }
    }, [playGames, topWinsState.topWins, dispatch, mapPicUrls]);

    return {
        ...topWinsState,
        getTopWins
    };
};
