import Typography from "@mui/material/Typography";

export const FooterDisclaimerContentFr = () => {
    return <>
        <Typography>
            Bienvenue sur OroPuro, le casino en ligne de premier choix où l'excitation rencontre le luxe ! Offrant une vaste sélection de jeux de haute qualité, des machines à sous classiques aux tables avec croupiers en direct, OroPuro garantit à chaque joueur une expérience de jeu incomparable. Notre plateforme est conçue pour offrir une navigation fluide, des paiements rapides et une sécurité de premier ordre, faisant d'OroPuro le choix idéal pour les débutants comme pour les joueurs expérimentés.
        </Typography>
        <Typography variant={'h4'}>
            Pourquoi choisir OroPuro ?
        </Typography>
        <ul>
            <li>Large choix de jeux : <span>Découvrez des centaines de machines à sous passionnantes, de jeux de table et d'options de casino en direct provenant des meilleurs fournisseurs de logiciels.</span></li>
            <li>Sécurisé et fiable : <span>Jouez en toute confiance, sachant que vos informations personnelles et financières sont protégées par les dernières technologies de cryptage.</span></li>
            <li>Retraits rapides : <span>Profitez de retraits rapides avec plusieurs méthodes de paiement fiables.</span></li>
            <li>Bonus exclusifs : <span>Bénéficiez de généreux bonus de bienvenue, promotions et récompenses de fidélité.</span></li>
            <li>Compatible mobile : <span>Accédez à OroPuro à tout moment, depuis votre ordinateur, tablette ou smartphone.</span></li>
        </ul>
        <Typography>
            Que vous soyez ici pour faire tourner les rouleaux, jouer au poker ou vivre l'excitation de la roulette en direct, OroPuro offre un divertissement de premier plan dans un environnement sécurisé et convivial. Rejoignez-nous dès aujourd'hui et commencez à gagner gros avec OroPuro Casino en ligne !
        </Typography>
    </>
}
