import {ElementType, FC} from "react";
import {alpha, Box, Stack, SvgIconProps, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

export const BottomMenuItem: FC<{link?: string, title: string, icon?: ElementType<SvgIconProps>, onClick?: () => void}> = ({link, title, icon: Icon, onClick}) => {
    const theme = useTheme()

    return <Stack
        {...(link !== undefined ? {component: Link, to: link} : {})}
        onClick={onClick}
        flex={1}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
            cursor: 'pointer',
            color: `${alpha('#E4DCD5', 0.6)} !important`
        }}
    >
        <Box
            height={'24px'}
            sx={{
                aspectRatio: '1/1',
            }}
        >
            {Icon && <Icon/>}
        </Box>
        <Typography
            fontSize={theme.typography.pxToRem(11)}
        >
            {title}
        </Typography>
    </Stack>
}