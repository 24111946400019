import { FC } from "react"
import {Box, useTheme} from "@mui/material";
import GrandPaDiamond from "../../../../assets/oropuro_redesigned_v2/grandpa_diamond_shape.webp";
import {BottomMenuItem} from "./BottomMenuItem";
import {useLanguage} from "../../../features/localisation/useLanguage";
import SportIcon from "../svg/SportIcon";
import SevenIcon from "../../common/icons/Seven";
import LiveCasinoIcon from "../../common/icons/LiveCasino";
import MenuIcon from "../svg/MenuIcon";

export const BottomMobileMenu: FC<{requestSwitchDrawer?: () => void}> = ({requestSwitchDrawer}) => {
    const theme = useTheme()
    const { languagePack: {pack: {argentina: {headerMenu: menuLang}}}} = useLanguage()

    const maxZIndex = Math.max(
        theme.zIndex.appBar,
        theme.zIndex.drawer,
        theme.zIndex.modal,
        theme.zIndex.tooltip
    );

    return <Box
        position={'relative'}
        minHeight={'66px'}
        display={'flex'}
        sx={{
            background: 'linear-gradient(180.00deg, rgba(255, 255, 255, 0.1) -18.939%,rgba(255, 255, 255, 0) 50%),radial-gradient(57.00% 53.00% at 50% 113.99999999999999%,rgba(243, 214, 129, 0.3),rgba(0, 0, 0, 0) 100%),rgb(16, 19, 23)'
        }}
    >
        {/* Gradient decoration */}
        <Box
            height={'25px'}
            position={'absolute'}
            left={0}
            right={0}
            top={0}
            sx={{
                pointerEvents: 'none',
                backgroundImage: 'linear-gradient(to top, #000, #0000)',
                transform: 'translateY(-100%)',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    left: 0, right: 0, bottom: 0,
                    height: '1px',
                    background: 'linear-gradient(to right, #B6864A 0%, #DEB366 8%, #F4D47E 20%, #FFEFD6 47%, #F3D681 53%, #F7CF78 60%, #B6864A 90%)',
                    zIndex: maxZIndex
                },
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    aspectRatio: '1/1.17',
                    width: '66px',
                    left: '50%',
                    transform: 'translateX(-50%) translateY(50%)',
                    backgroundImage: `url(${GrandPaDiamond})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    zIndex: maxZIndex
                }
            }}
        >
        </Box>


        <BottomMenuItem link={'/sport'} title={menuLang.sport} icon={SportIcon}/>
        <BottomMenuItem link={'/games'} title={menuLang.gamesSubCatTitle} icon={SevenIcon}/>
        <BottomMenuItem link={'/'} title={menuLang.home}/>
        <BottomMenuItem link={'/live_casino'} title={menuLang.casino} icon={LiveCasinoIcon}/>
        <BottomMenuItem title={menuLang.menu} icon={MenuIcon} onClick={requestSwitchDrawer}/>
    </Box>
}