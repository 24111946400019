import Typography from "@mui/material/Typography";

export const FooterDisclaimerContentRu = () => {
    return <>
        <Typography>
            Добро пожаловать в OroPuro — ведущее онлайн-казино, где азарт встречается с роскошью! Огромный выбор высококачественных игр — от классических слотов до живых столов с дилерами — гарантирует каждому игроку неповторимый опыт. Наша платформа предлагает удобную навигацию, быстрые выплаты и первоклассную безопасность, делая OroPuro идеальным выбором как для новичков, так и для опытных игроков.
        </Typography>
        <Typography variant={'h4'}>
            Почему стоит выбрать OroPuro?
        </Typography>
        <ul>
            <li>Широкий выбор игр: <span>Откройте для себя сотни увлекательных слотов, настольных игр и живое казино от ведущих поставщиков программного обеспечения.</span></li>
            <li>Безопасность и надёжность: <span>Играйте с уверенностью, зная, что ваши личные и финансовые данные защищены передовыми технологиями шифрования.</span></li>
            <li>Быстрые выплаты: <span>Наслаждайтесь быстрыми выводами средств через надёжные платёжные методы.</span></li>
            <li>Эксклюзивные бонусы: <span>Воспользуйтесь щедрыми приветственными бонусами, акциями и программами лояльности.</span></li>
            <li>Мобильная версия: <span>Доступ к OroPuro в любое время и в любом месте — с компьютера, планшета или смартфона.</span></li>
        </ul>
        <Typography>
            Независимо от того, хотите ли вы вращать барабаны, попробовать силы в покере или ощутить азарт живой рулетки, OroPuro предлагает первоклассное развлечение в безопасной и удобной среде. Присоединяйтесь сегодня и начните выигрывать вместе с OroPuro Online Casino!
        </Typography>
    </>
}
